/* global */
module.exports =
  /** @ngInject */
  function config ($stateProvider, msNavigationServiceProvider) {
    // State
    $stateProvider
      .state('app.form', {
        abstract: true,
        url: '/form',
        lazyLoad: $transition$ => {
          const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad')
          return import(
            /* webpackChunkName: "form.module" */ './form.module.js'
          )
            .then(mod => {
              return $ocLazyLoad.inject(mod.default)
            })
            .catch(err => {
              throw new Error('Ooops, something went wrong, ' + err)
            })
        }
      })
      .state('app.form.list', {
        url: '/?:filter',
        params: {
          filter: {
            dynamic: true
          }
        },
        views: {
          'content@app': {
            template: require('app/modules/components/tables/kendo-grid/grid.html'),
            controller: 'FormsController',
            controllerAs: 'vm'
          }
        },
        bodyClass: 'form'
      })
      .state('app.form.create', {
        url: '/create',
        views: {
          'content@app': {
            template: require('./templates-grid/form-templates-grid.html'),
            controller: 'FormTemplatesGridController',
            controllerAs: 'vm'
          }
        },
        bodyClass: 'form form-templates-grid',
        resolve: {
          ResolvedFormTemplates: Form => {
            return Form.find({ filter: { where: { isTemplate: true } } })
              .$promise
          }
        }
      })
      .state('app.form.show', {
        url: '/show/?:id',
        views: {
          'content@app': {
            template: require('./show/form-show.html'),
            controller: 'FormShowController',
            controllerAs: 'vm'
          }
        },
        lazyLoad: () => {
          return import(
            /* webpackChunkName: "icons" */ '../../core/assets/icons/selection.json'
          )
            .then(mod => {
              global.Icons = mod.default
            })
            .catch(err => {
              throw new Error('Ooops, something went wrong, ' + err)
            })
        },
        bodyClass: 'form',
        resolve: {
          ResolvedData: (
            $rootScope,
            $stateParams,
            Form,
            Field,
            MultiTranslateService
          ) => {
            if (!$stateParams.id || $stateParams.id === '') {
              return { form: null, fields: [] }
            }
            return Form.getFormData({
              formId: $stateParams.id
            }).$promise.then(res => {
              let { form, fields } = res
              fields = fields.map(field =>
                MultiTranslateService.getForView(
                  Field,
                  $rootScope.currentLang,
                  field
                )
              )
              return { form, fields }
            })
          },
          ResolvedUsers: UserModel => UserModel.find({}).$promise,
          ResolvedCertificates: (
            Certificate,
            $rootScope,
            MultiTranslateService
          ) =>
            Certificate.find({}).$promise.then(res =>
              res.map(certificate =>
                MultiTranslateService.getForView(
                  Certificate,
                  $rootScope.currentLang,
                  certificate
                )
              )
            ),
          ResolvedRoles: Role =>
            Role.find({
              filter: {
                where: {
                  name: {
                    nin: [
                      '$authenticated',
                      '$unauthenticated',
                      '$everyone',
                      '$owner'
                    ]
                  }
                },
                fields: {
                  id: true,
                  nicename: true,
                  disableUse: true
                }
              }
            }).$promise,
          ResolvedFormTableViews: (TableSetup, $stateParams) =>
            TableSetup.find({
              filter: {
                where: { state: 'app.reports.form.' + $stateParams.id },
                fields: { id: true, name: true }
              }
            }).$promise
        }
      })
      .state('app.form.display', {
        url: '/display/:id?:formId?:print',
        views: {
          'content@app': {
            template: require('./review/form-review.html'),
            controller: 'FormReviewController',
            controllerAs: 'vm'
          }
        },
        bodyClass: 'form',
        resolve: {
          sessionData: () => null,
          isDisabled: () => false,
          ResolvedData: (
            FormData,
            Field,
            $stateParams,
            $rootScope,
            MultiTranslateService,
            Form
          ) => {
            return new Promise(async (resolve, reject) => {
              let form, fields
              if ($stateParams.id === 'new-form') {
                form = await Form.findOne({
                  filter: {
                    where: { id: $stateParams.formId }
                  }
                }).$promise
                form = form ? form.toJSON() : null
                form.fieldSettings = form?.fieldSettings
                  ? JSON.parse(form.fieldSettings)
                  : {}
                form.fields = form.fields.filter(field => !field.unused)
                const fieldIds = form.fields.map(formField => formField.fieldId)
                fields = await Field.getFieldsWithInnerFields({
                  fieldIds
                }).$promise
                fields = fields.map(field =>
                  MultiTranslateService.getForView(
                    Field,
                    $rootScope.currentLang,
                    field
                  )
                )
                resolve({
                  formDatas: [],
                  users: [],
                  form,
                  fields,
                  workflows: []
                })
              } else {
                try {
                  const result = await FormData.findInstance({
                    where: { id: $stateParams.id }
                  }).$promise
                  const { users, formData, workflows } = result
                  form = result.form
                  fields = result.fields.map(field =>
                    MultiTranslateService.getForView(
                      Field,
                      $rootScope.currentLang,
                      field
                    )
                  )

                  resolve({
                    formDatas: [formData],
                    users,
                    form,
                    fields,
                    workflows
                  })
                } catch (err) {
                  reject(err)
                }
              }
            })
          },
          ResolvedForms: Form =>
            Form.find({
              filter: {
                where: {
                  isTemplate: false
                }
              }
            }).$promise,
          parentLinkedForm: () => null
        }
      })
      .state('app.form.review', {
        url: '/review/:id',
        resolve: {
          redirect: ($state, $stateParams) => {
            return new Promise(async (resolve, reject) => {
              resolve($state.go('app.form.display', $stateParams))
            })
          }
        }
      })
      .state('app.form.standalone-create', {
        url: '/create/:formId',
        resolve: {
          redirect: ($state, $stateParams) => {
            return new Promise(async (resolve, reject) => {
              resolve(
                $state.go('app.form.display', {
                  id: 'new-form',
                  formId: $stateParams.formId
                })
              )
            })
          }
        }
      })
    // Navigation
    msNavigationServiceProvider.saveItem('apps.modeling.form', {
      title: 'FORM.FORMS',
      icon: 'icon-text-box-check-outline',
      state: 'app.form.list',
      weight: 20,
      acls: [{ model: 'Form', method: 'create' }]
    })
    // msNavigationServiceProvider.saveItem('apps.form.list', {
    //   title: 'FORM.ALL_FORMS',
    //   state: 'app.form.list',
    //   weight: 601,
    //   acls: [{ model: 'Form', method: 'find' }]
    // })
    // msNavigationServiceProvider.saveItem('apps.form.show', {
    //   title: 'FORM.NEW_FORM',
    //   state: 'app.form.show',
    //   weight: 602
    //   // acls: [{ model: 'From', method: 'create' }]
    // })
  }
