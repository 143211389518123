/* global _ */

/** @ngInject */
function DevicesController (
  $scope,
  $translate,
  $rootScope,
  Page,
  PermissionUtils,
  KendoGridHelper,
  ViewsService,
  htmlWork,
  Stock
) {
  const initScreen = async function initScreen () {
    $scope.PermissionUtils = PermissionUtils

    $scope.title = $rootScope.title = $translate.instant('HOME.DEVICES')

    Page.setTitleText($scope.title)

    const baseFilter = {
      where: {
        and: [
          { isSerial: true },
          { serial: { neq: null } },
          { serial: { neq: '' } }
        ]
      },
      fields: {
        id: true,
        sku: true,
        serial: true,
        skuRev: true
      }
    }

    const tableColumns = [
      // SKU
      {
        uniqueId: '336610cc-484d-4f0a-8a39-94aff606c6e0',
        field: 'sku',
        translateCode: 'WF.SKU',
        template: data => {
          return data.sku
        }
      },
      // REV
      {
        uniqueId: '8ce8202a-0f91-443d-8744-bf4fdf8c9936',
        field: 'skuRev',
        translateCode: 'WF.skuRev',
        template: data => {
          return data.skuRev || '--'
        }
      },
      // SERIAL
      {
        uniqueId: '910b0ba7-7b74-48e9-b1a6-0c3792a16802',
        field: 'serial',
        translateCode: 'WF.SERIAL',
        template: data => {
          return data.serial
        }
      },
      // ACTIONS
      {
        uniqueId: 'a025a47b-abad-4dd7-89a7-9ea41b22a81f',
        field: 'id',
        translateCode: 'COMMON.ACTIONS',
        filterable: false,
        sortable: false,
        headerAttributes: {
          class: 'actions-column-header'
        },
        trustedTemplate: data => {
          return `<md-button ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button','show-button']"
                            data-testid="show.device.${data.id}" ui-sref="app.devices.show({id: '${data.id}'})" ui-sref-opts="{inherit: false}">
                        <md-icon md-font-icon="icon-eye" class="s16 blue-500-fg"></md-icon>
                    </md-button>`
        }
      }
    ]

    const { defaultTableColumns } = ViewsService.generateCustomFieldColumns(
      'Stock',
      tableColumns
    )

    const { columns, selectedViewId } =
      ViewsService.getTablesColumns(defaultTableColumns)

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter)
    )

    const defaultTableSetup = {
      // stateName
      // ignoreParams
      find: Stock.find,
      count: Stock.count,
      cleanBaseFilter: baseFilter || {},
      baseFilter: newBaseFilter,
      selectedViewId,
      columns: defaultTableColumns
    }

    const defaultTableToolbarSetup = {
      // stateName
      columns: defaultTableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters,
      selectedViewId,
      title: $scope.title
    }

    $scope.kendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.kendoGrid.isBound()

    $scope.tableToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.kendoGrid,
      $scope
    )

    $scope.$apply()
  }

  initScreen()

  $scope.headerOptions = {
    icon: 'icon-file-tree',
    template: require('../../../templates/headers/simple.html'),
    title: $scope.title,
    fabButton: {}
  }
}

module.exports = DevicesController
