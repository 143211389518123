/* global _ */
const debug = require('debug')('nextplus:tools')
/** @ngInject */
function ToolsController (
  $scope,
  $rootScope,
  $translate,
  KendoGridHelper,
  ViewsService,
  PermissionUtils,
  Page,
  Tool
) {
  const initScreen = async function initScreen () {
    debug('init tools')
    $scope.title = $rootScope.title = $translate.instant('Tool.tools')
    Page.setTitleText($scope.title)
    $scope.PermissionUtils = PermissionUtils

    const tableColumns = [
      // NAME
      {
        uniqueId: '31262f28-8206-42f0-8902-1312fee93adc',
        field: 'name',
        translateCode: 'Tool.name'
        // media: '(min-width: 768px)'
      },
      // DESCRIPTION
      {
        uniqueId: '5c1424e2-24e1-45d4-a865-dd903698d33a',
        field: 'description',
        translateCode: 'Tool.description'
      },
      // SKU
      {
        uniqueId: '88e3183e-3b90-42ce-bc90-e49bcccf473a',
        field: 'sku',
        translateCode: 'Tool.sku'
      },
      // IS_SERIAL
      {
        uniqueId: '2f93bda3-2e70-4656-8936-b564cd0d4d38',
        field: 'isSerial',
        translateCode: 'Tool.IS_SERIAL',
        type: 'boolean',
        filterable: true
      },
      // ACTIONS
      {
        uniqueId: '7d007d10-9984-4fbe-8ac7-1795687ffaa6',
        field: 'id',
        translateCode: 'COMMON.ACTIONS',
        headerAttributes: {
          class: 'actions-column-header'
        },
        // media: '(min-width: 768px)',
        filterable: false,
        sortable: false,
        trustedTemplate: data => {
          return `<div layout="row" layout-align="start center" style="direction: ${$rootScope.dir}">
                    <md-button ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button','show-button']" data-testid="edit-${data.id}" ui-sref="app.tool.show({id: '${data.id}'})">
                      <md-icon md-font-icon="icon-eye" class="s20"></md-icon>
                    </md-button>
                  </div>`
        }
      }
    ]

    const basicFields = {
      id: true,
      name: true,
      created: true,
      description: true,
      isSerial: true,
      translations: true,
      fallbackStatus: true,
      sku: true
    }

    const { defaultTableColumns, customFieldsDisplay } =
      ViewsService.generateCustomFieldColumns('Tool', tableColumns)

    const baseFilter = {
      where: {},
      order: 'created DESC',
      fields: _.merge(basicFields, customFieldsDisplay)
    }

    const { columns, selectedViewId } =
      ViewsService.getTablesColumns(defaultTableColumns)

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter)
    )

    const defaultTableSetup = {
      // stateName
      // ignoreParams
      model: Tool,
      find: Tool.find,
      count: Tool.count,
      cleanBaseFilter: baseFilter,
      baseFilter: newBaseFilter,
      selectedViewId,
      columns: defaultTableColumns,
      encodeTitles: true
    }

    const defaultTableToolbarSetup = {
      // stateName
      columns: defaultTableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters,
      selectedViewId,
      title: $scope.title
    }

    $scope.kendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.kendoGrid.isBound()

    $scope.tableToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.kendoGrid,
      $scope
    )

    $scope.$applyAsync()
  }

  initScreen()

  $scope.headerOptions = {
    icon: 'icon-basket',
    template: require('app/templates/headers/simple.html'),
    title: $scope.title,
    fabButton: {
      template: require('app/templates/headers/fab-button.html'),
      action: null,
      icon: 'icon-plus',
      href: '',
      disabled: !PermissionUtils.isPermit('Tool', 'create'),
      state: 'app.tool.show',
      showExpression: () => true
    }
  }
}

module.exports = ToolsController
