/* global */

const statusColorHelper = require('../../../../../../common/services/status-color-helper')

/** @ngInject */
function ProductionEntityHelper (htmlWork, $translate) {
  const generateStatusTemplate = function generateStatusTemplate (
    status,
    color
  ) {
    const backgroundColor = color
    const textColor = statusColorHelper.getReadableFontColor(backgroundColor)
    return `<div style="white-space: nowrap; text-overflow: ellipsis; ">
                      <span class="form-status" style="background-color:${htmlWork.escapeHTMLQuotes(
                        backgroundColor
                      )}; color:${textColor}">${htmlWork.htmlEncode(
      status
    )}</span>
                    </div>`
  }

  const availableMaxLogPeriods = [
    {
      name: $translate.instant(
        'PRODUCTION_ENTITY.ADD_PARAMETER_WIZARD.MAX_LOG_PERIODS.TWELVE_HOURS'
      ),
      value: 720
    },
    {
      name: $translate.instant(
        'PRODUCTION_ENTITY.ADD_PARAMETER_WIZARD.MAX_LOG_PERIODS.TWENTY_FOUR_HOURS'
      ),
      value: 1440
    }
  ]
  const availableTypes = [
    {
      name: 'String',
      value: 'string'
    },
    {
      name: 'Number',
      value: 'number'
    },
    {
      name: 'Boolean',
      value: 'boolean'
    },
    {
      name: 'Date',
      value: 'date'
    }
  ]
  return {
    generateStatusTemplate,
    availableMaxLogPeriods,
    availableTypes
  }
}

module.exports = ProductionEntityHelper
