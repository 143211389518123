/* global angular _ kendo */
'use strict'
import AddParameterController from './add-adit-parameters/add-edit-parameter-dialog.controller'
import ProductionEntityTriggersController from './add-edit-triggers/add-edit-triggers.controller'
const UUID = require('uuid')

require('./add-edit-production-entity.scss')

/** @ngInject */
function ProductionEntityEditController (
  $translate,
  $scope,
  $stateParams,
  ResolvedProductionEntity,
  ProductionEntity,
  FormlyHelper,
  ProductionEntityType,
  $mdToast,
  $state,
  $rootScope,
  ViewsService,
  KendoGridHelper,
  Page,
  $mdDialog,
  ProductionEntityHelper,
  ResolvedOpcUaServers
) {
  const vm = this
  const STATUSES_TYPES = {
    UP: 'UP',
    DOWN: 'DOWN'
  }
  const statusesTypes = [
    {
      value: STATUSES_TYPES.UP,
      name: $translate.instant('PRODUCTION_ENTITY.STATUSES_TYPES.UP')
    },
    {
      value: STATUSES_TYPES.DOWN,
      name: $translate.instant('PRODUCTION_ENTITY.STATUSES_TYPES.DOWN')
    }
  ]
  const defaultStatuses = statusesTypes.map(status => ({
    id: UUID(),
    name: status.name,
    color: status.value === STATUSES_TYPES.UP ? '#b5e61d' : '#f09574',
    status: status.value,
    deletedAt: null
  }))
  const maxLogPeriodsByValue =
    ProductionEntityHelper.availableMaxLogPeriods.reduce(
      (acc, maxLogPeriod) => {
        acc[maxLogPeriod.value] = maxLogPeriod.name
        return acc
      },
      {}
    )
  const typesByValue = ProductionEntityHelper.availableTypes.reduce(
    (acc, type) => {
      acc[type.value] = type.name
      return acc
    },
    {}
  )

  const errorsHandler = function errorsHandler (err) {
    console.error(err)
    let message = $translate.instant('PRODUCTION_ENTITY.OPC_UA_SERVER.ERROR')
    if (
      [
        'NAME_REQUIRED',
        'TYPE_REQUIRED',
        'AT_LEAST_ONE_STATUS_REQUIRED',
        'STATUS_NAME_REQUIRED',
        'STATUS_NAMES_MUST_BE_UNIQUE',
        'AT_LEAST_ONE_DOWN_STATUS_REQUIRED',
        'AT_LEAST_ONE_UP_STATUS_REQUIRED',
        'PARAMETER_NAME_REQUIRED',
        'PARAMETER_ID_REQUIRED',
        'DUPLICATE_PARAMETERS_NOT_ALLOWED',
        'PARAMETER_SERVER_ID_REQUIRED',
        'PARAMETER_NODE_ID_REQUIRED',
        'PARAMETER_TYPE_REQUIRED',
        'PARAMETER_TYPE_INVALID',
        'PARAMETER_DATA_TYPE_REQUIRED',
        'PARAMETER_DATA_TYPE_INVALID',
        'PARAMETER_SAMPLE_RATE_REQUIRED',
        'PARAMETER_SAMPLE_RATE_INVALID',
        'TRIGGER_NAME_REQUIRED',
        'AT_LEAST_ONE_CONDITION_REQUIRED',
        'CONDITION_TYPE_REQUIRED',
        'AT_LEAST_ONE_VALUE_REQUIRED',
        'CONDITION_VALUE_FIELD_REQUIRED',
        'CONDITION_VALUE_OPERATOR_REQUIRED',
        'CONDITION_FIELD_DOES_NOT_EXIST',
        'AT_LEAST_ONE_ACTION_REQUIRED',
        'ACTION_TYPE_REQUIRED',
        'ACTION_SMS_CONTENT_REQUIRED',
        'ACTION_SMS_USERS_REQUIRED',
        'ACTION_EMAIL_VALUE_REQUIRED',
        'ACTION_EMAIL_USERS_REQUIRED',
        'ACTION_EMAIL_TITLE_REQUIRED',
        'ACTION_EMAIL_CONTENT_REQUIRED',
        'ACTION_STATUS_REQUIRED',
        'ACTION_TYPE_INVALID'
      ].includes(err.data.error.code)
    ) {
      if (err?.data?.error?.details) {
        message = $translate.instant(
          'PRODUCTION_ENTITY.ERRORS.' + err.data.error.code,
          err.data.error.details
        )
      } else {
        message = $translate.instant(
          'PRODUCTION_ENTITY.ERRORS.' + err.data.error.code
        )
      }
    }
    $rootScope.showErrorToast(message)
  }

  $scope.save = function save () {
    $rootScope.loadingProgress = true
    if (ResolvedProductionEntity) {
      ProductionEntity.prototype$patchAttributes(
        { id: ResolvedProductionEntity.id },
        vm.productionEntity
      )
        .$promise.then(() => {
          $rootScope.showSuccessToast(
            $translate.instant('PRODUCTION_ENTITY.SAVED_SUCCESSFULLY')
          )
          $rootScope.loadingProgress = false
          $state.go('app.production-entity.list')
        })
        .catch(err => {
          errorsHandler(err)
          $rootScope.loadingProgress = false
        })
    } else {
      ProductionEntity.create(vm.productionEntity)
        .$promise.then(() => {
          $rootScope.showSuccessToast(
            $translate.instant('PRODUCTION_ENTITY.SAVED_SUCCESSFULLY')
          )
          $rootScope.loadingProgress = false
          $state.go('app.production-entity.list')
        })
        .catch(err => {
          errorsHandler(err)
          $rootScope.loadingProgress = false
        })
    }
  }

  $scope.addParameter = function addParameter (id = null) {
    let oldParameter = null
    if (id) {
      const index = vm.productionEntity.parameters.findIndex(p => p.id === id)
      oldParameter = vm.productionEntity.parameters[index]
    }
    return $mdDialog
      .show({
        controller: AddParameterController,
        controllerAs: 'vm',
        template: require('./add-adit-parameters/add-edit-parameter-dialog.template.html'),
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        escapeToClose: false,
        resolve: {
          ResolvedOpcUaServers: function (OpcUaServer) {
            return OpcUaServer.initialBrowseAllServers().$promise
          }
        },
        locals: {
          parameter: oldParameter,
          parameters: vm.productionEntity.parameters || []
        }
      })
      .then(function (parameter) {
        if (parameter) {
          vm.productionEntity.parameters = vm.productionEntity.parameters || []
          if (id) {
            const dataItem = $scope.kendoGrid.instance.dataSource
              .data()
              .find(item => item.id === id)
            $scope.kendoGrid.instance.dataSource.remove(dataItem)
            $scope.kendoGrid.instance.dataSource.add(parameter)
          } else {
            vm.productionEntity.parameters.push(parameter)
            $scope.kendoGrid.instance.dataSource.add(parameter)
          }
        }
      })
  }

  const createEditTrigger = function (index = null) {
    let trigger = null
    if (index !== null) {
      trigger = _.cloneDeep(vm.productionEntity.triggers[index])
    }
    const statuses = vm.productionEntity.statuses.filter(
      status => !status.deletedAt
    )
    $mdDialog
      .show({
        controller: ProductionEntityTriggersController,
        template: require('./add-edit-triggers/add-edit-triggers.template.html'),
        parent: angular.element(document.body),
        targetEvent: '',
        multiple: true,
        locals: {
          trigger,
          type: 'triggers',
          context: 'production-entity',
          mergedTagFields: getParameters(),
          fields: buildConditionsFields(statuses),
          categories: [
            {
              id: 'variables',
              name: $translate.instant(
                'PRODUCTION_ENTITY.TRIGGERS_DIALOG.VARIABLES.TITLE'
              )
            },
            {
              id: 'parameters',
              name: $translate.instant(
                'PRODUCTION_ENTITY.TRIGGERS_DIALOG.PARAMETERS.TITLE'
              )
            }
          ],
          statuses
        },
        resolve: {},
        clickOutsideToClose: false,
        escapeToClose: false,
        fullscreen: true
      })
      .then(
        function (response) {
          if (index === null) {
            // new trigger
            vm.productionEntity.triggers.push(response)
          } else {
            // edit trigger
            vm.productionEntity.triggers[index] = response
          }
        },
        function () {}
      )
  }

  const buildConditionsFields = function buildConditionsFields (statuses) {
    const convertedFields = [...getVariables(statuses), ...getParameters()]
    return convertedFields
  }

  const getVariables = function getVariables (statuses) {
    const variables = [
      {
        name: 'today',
        type: 'today'
      },
      {
        name: 'name',
        type: 'string'
      },
      {
        name: 'type',
        type: 'string'
      },
      {
        name: 'status',
        type: 'select',
        values: statuses.map(status => status.name)
      }
    ]
    return variables.map(variable => {
      const variableObject = {
        id: `variable_${variable.name}`,
        title: `(Entity Variable) ${$translate.instant(
          `PRODUCTION_ENTITY.TRIGGERS_DIALOG.VARIABLES.${variable.name}`
        )}`,
        type: variable.type,
        category: 'variables'
      }
      if (!_.isNil(variable.values) && variable.values.length) {
        variableObject.values = variable.values
      }
      return variableObject
    })
  }

  const getParameters = function getParameters () {
    return vm.productionEntity.parameters.map(param => ({
      id: param.id,
      fieldId: param.id,
      category: 'parameters',
      title: param.niceName,
      type: param.type
    }))
  }

  const initScreen = async function initScreen () {
    vm.edit = !!$stateParams.id
    const defaultStatus = defaultStatuses.find(
      status =>
        status.name ===
        $translate.instant('PRODUCTION_ENTITY.STATUSES_TYPES.DOWN')
    )
    vm.productionEntity = {
      name: '',
      productionEntityTypeId: null,
      statuses: defaultStatuses,
      status: defaultStatus.name,
      statusId: defaultStatus.id,
      triggers: [],
      parameters: []
    }
    if (ResolvedProductionEntity) {
      vm.productionEntity = {
        ...vm.productionEntity,
        ...ResolvedProductionEntity.toJSON()
      }
    }
    $scope.opcUaServersById = ResolvedOpcUaServers.reduce((acc, server) => {
      acc[server.id] = server
      return acc
    }, {})
    $scope.title = vm.edit
      ? `${$translate.instant('PRODUCTION_ENTITY.TITLE')}: ${
          vm.productionEntity.name
        }`
      : $translate.instant('PRODUCTION_ENTITY.CREATE_PRODUCTION_ENTITY')
    Page.setTitleText($scope.title)
    const staticFields = [
      {
        key: 'name',
        type: 'input',
        templateOptions: {
          label: $translate.instant('PRODUCTION_ENTITY.FIELDS.NAME'),
          required: true
        }
      },
      {
        key: 'productionEntityTypeId',
        type: 'modelSelect',
        className: 'layout-column',
        templateOptions: {
          required: true,
          label: $translate.instant('PRODUCTION_ENTITY.FIELDS.TYPE'),
          findMethod: ProductionEntityType.find,
          mapObject: { id: 'id', name: 'name' },
          baseFilterObject: {
            where: {},
            fields: {
              id: true,
              name: true
            }
          }
        }
      },
      {
        key: 'statuses',
        type: 'repeatingTemplate',
        templateOptions: {
          layout: 'row',
          title: $translate.instant('PRODUCTION_ENTITY.FIELDS.STATUSES'),
          btnText: $translate.instant('WF.ADD'),
          filter: status => !status.deletedAt,
          handleRemove: function (index, item, model) {
            const filteredStatuses = []
            const deletedStatuses = []
            vm.productionEntity.statuses.forEach(status => {
              if (status.deletedAt) {
                deletedStatuses.push(status)
              } else {
                filteredStatuses.push(status)
              }
            })
            const status = filteredStatuses[index]
            if (status?.id === vm.productionEntity.statusId) {
              const mdToast = $mdToast.nextplus({
                position: $rootScope.toastLocation,
                parent: 'body',
                theme: 'warning-toast',
                hideDelay: 3000
              })
              const errorMessage = $translate.instant(
                'PRODUCTION_ENTITY.ERRORS.CANNOT_REMOVE_CURRENT_STATUS'
              )
              $mdToast.updateTextContent(errorMessage)
              $mdToast.show(mdToast)
              return
            }
            filteredStatuses.splice(index, 1)
            vm.productionEntity.statuses = [
              ...filteredStatuses,
              ...deletedStatuses
            ]
          },
          addParameters: function () {
            return {
              id: UUID(),
              deletedAt: null
            }
          },
          fields: [
            {
              key: 'name',
              type: 'input',
              className: 'flex-45 margin-left-20 margin-right-20',
              templateOptions: {
                type: 'text',
                required: true,
                label: $translate.instant('PRODUCTION_ENTITY.STATUS_NAME')
              }
            },
            {
              key: 'status',
              type: 'select',
              className: 'flex-45 margin-right-20',
              templateOptions: {
                required: true,
                options: statusesTypes,
                label: $translate.instant('PRODUCTION_ENTITY.STATUS_TYPE')
              }
            },
            {
              key: 'color',
              type: 'colorPalette',
              className: 'flex-10',
              templateOptions: {
                colorPaletteOptions: {
                  opacity: false,
                  tileSize: 30,
                  paletteDisplayed: false
                },
                label: $translate.instant('PRODUCTION_ENTITY.STATUS_COLOR')
              }
            }
          ]
        }
      },
      {
        key: 'triggers',
        type: 'repeatedList',
        templateOptions: {
          btnText: $translate.instant('WF.ADD'),
          label: 'name',
          title: $translate.instant('PRODUCTION_ENTITY.TRIGGERS_DIALOG.TITLE'),
          hideHeader: false,
          hasExtraButton: false,
          limit: 0,
          onAdd: function () {
            createEditTrigger()
          },
          onEdit: function (editedObject, index) {
            createEditTrigger(index)
          },
          onSorted: function (newArray, oldIndex, newIndex) {
            vm.productionEntity.triggers = newArray
          }
        }
      }
    ]
    $scope.fields = FormlyHelper.buildFields(staticFields, ProductionEntity)

    const tableColumns = [
      // SERVER
      {
        uniqueId: 'b1f3c3d2-3c33-43d2-a2e1-1a9d6af47c51',
        field: 'serverId',
        translateCode: 'PRODUCTION_ENTITY.PARAMETERS_TABLE.FIELDS.SERVER',
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            operator: 'eq',
            suggestionOperator: 'eq',
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'name',
                dataValueField: 'id',
                dataSource: new kendo.data.DataSource({
                  data: ResolvedOpcUaServers
                }),
                valuePrimitive: true
              })
            }
          }
        },
        template: data => {
          const server = $scope.opcUaServersById[data.serverId] || null
          return server ? server.name : ''
        }
      },
      // NODE_ID
      {
        uniqueId: '74a7824d-8957-4a4d-9745-d1e0428dc773',
        field: 'nodeId',
        translateCode: 'PRODUCTION_ENTITY.PARAMETERS_TABLE.FIELDS.NODE_ID'
      },
      // NAME
      {
        uniqueId: '7fe43b6e-d042-493c-b621-0f08e5e89abc',
        field: 'name',
        translateCode: 'PRODUCTION_ENTITY.PARAMETERS_TABLE.FIELDS.NAME'
      },
      // NICE_NAME
      {
        uniqueId: '2990ad3d-3c33-43d2-a2e1-1a9d6af47c51',
        field: 'niceName',
        translateCode: 'PRODUCTION_ENTITY.PARAMETERS_TABLE.FIELDS.NICE_NAME'
      },
      // TYPE
      {
        uniqueId: '415dc58b-1b97-4011-a609-be866c2d6202',
        field: 'type',
        translateCode: 'PRODUCTION_ENTITY.PARAMETERS_TABLE.FIELDS.TYPE',
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            operator: 'eq',
            suggestionOperator: 'eq',
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'name',
                dataValueField: 'value',
                dataSource: new kendo.data.DataSource({
                  data: ProductionEntityHelper.availableTypes
                }),
                valuePrimitive: true
              })
            }
          }
        },
        template: data => typesByValue[data.type]
      },
      // PARAMETER_TYPE
      {
        uniqueId: 'fc416630-8240-493f-bc9f-c2afdfa27127',
        field: 'parameterType',
        translateCode:
          'PRODUCTION_ENTITY.PARAMETERS_TABLE.FIELDS.PARAMETER_TYPE',
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            operator: 'eq',
            suggestionOperator: 'eq',
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'name',
                dataValueField: 'value',
                dataSource: new kendo.data.DataSource({
                  data: [
                    {
                      name: 'OPC UA',
                      value: 'OPC UA'
                    },
                    {
                      name: 'API',
                      value: 'API'
                    }
                  ]
                }),
                valuePrimitive: true
              })
            }
          }
        }
      },
      // SAMPLE_RATE
      {
        uniqueId: '3bcf6283-ccc5-46db-b8c8-49ebb9d98cd0',
        field: 'maxLogPeriod',
        translateCode:
          'PRODUCTION_ENTITY.PARAMETERS_TABLE.FIELDS.MAX_LOG_PERIOD',
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            operator: 'eq',
            suggestionOperator: 'eq',
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'name',
                dataValueField: 'value',
                dataSource: new kendo.data.DataSource({
                  data: ProductionEntityHelper.availableMaxLogPeriods
                }),
                valuePrimitive: true
              })
            }
          }
        },
        template: data => maxLogPeriodsByValue[data.maxLogPeriod]
      },
      // PRECISION
      {
        uniqueId: 'g1h2j3k4-5678-9012-3456-789012345678',
        field: 'precision',
        translateCode: 'PRODUCTION_ENTITY.PARAMETERS_TABLE.FIELDS.PRECISION',
        type: 'number'
      },
      // ACTIONS
      {
        uniqueId: 'z2x3c4v5-8957-4a4d-9745-d1e0428dc773',
        field: 'id',
        translateCode: 'COMMON.ACTIONS',
        headerAttributes: {
          class: 'actions-column-header'
        },
        // media: '(min-width: 768px)',
        filterable: false,
        sortable: false,
        trustedTemplate: data => {
          let html = `<div layout="row" layout-align="start center" style="direction: ${$rootScope.dir}">`
          html += `<md-button ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button','show-button']"
                   data-testid="edit-${data.id}"
                   ng-click="addParameter('${data.id}')">
                    <md-icon md-font-icon="icon-pencil" class="s20"></md-icon>
                  </md-button>`
          html += `<md-button ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-warn'] : ['md-icon-button','show-button']"
                   data-testid="delete-${data.id}"
                   ng-click="vm.deleteParameter('${data.id}')">
                    <md-icon md-font-icon="icon-delete" class="s20 md-warn"></md-icon>
                  </md-button>`
          html += '</div>'
          return html
        }
      }
    ]

    const baseFilter = {}

    const stateName = 'app.production-entity.edit.parameters'
    const { columns, selectedViewId } = ViewsService.getTablesColumns(
      tableColumns,
      stateName
    )

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter),
      stateName
    )

    const defaultTableSetup = {
      stateName,
      ignoreParams: true,
      data: vm.productionEntity.parameters || [],
      serverSide: false,
      cleanBaseFilter: baseFilter || {},
      baseFilter: newBaseFilter,
      selectedViewId,
      columns,
      encodeTitles: true
    }
    const defaultTableToolbarSetup = {
      // stateName
      columns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters,
      selectedViewId,
      title: $translate.instant('PRODUCTION_ENTITY.PARAMETERS_TABLE.TITLE')
    }

    $scope.kendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    $scope.tableToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.kendoGrid,
      $scope
    )

    $scope.$apply()
  }

  vm.deleteParameter = function (id) {
    const index = vm.productionEntity.parameters.findIndex(p => p.id === id)
    if (index !== -1) {
      vm.productionEntity.parameters.splice(index, 1)
      const dataItem = $scope.kendoGrid.instance.dataSource
        .data()
        .find(item => item.id === id)
      $scope.kendoGrid.instance.dataSource.remove(dataItem)
    }
  }

  initScreen()
  $scope.headerOptions = {
    icon: 'icon-account',
    template: require('app/templates/headers/simple.html'),
    title: $scope.title,
    fabButton: {}
  }
}

module.exports = ProductionEntityEditController
