/* global _ */

/** @ngInject */
function WorkorderTemplateDialogController (
  $scope,
  $rootScope,
  $mdDialog,
  $mdToast,
  $translate,
  locals,
  ResolvedGroups,
  Workorder,
  WorkorderUtils,
  ResolvedDefaultWorkorderType
) {
  $scope.workorderTemplate = locals.template
  $scope.workorderTemplate.customWorkorderNumber = false
  $scope.workorderTemplate.workorderNumber = null
  $scope.groups = ResolvedGroups
  $scope.defaultWorkorderType = ResolvedDefaultWorkorderType
  $scope.isValid = function isValid () {
    if (
      !$scope.workorderTemplate.product ||
      !$scope.workorderTemplate.sku ||
      !$scope.workorderTemplate.rev
    ) {
      return false
    }
    if (
      !$scope.workorderTemplate.quantity ||
      $scope.workorderTemplate.quantity < 0
    ) {
      return false
    }
    if (
      !$scope.workorderTemplate.name ||
      $scope.workorderTemplate.name === ''
    ) {
      return false
    }
    if (!$scope.workorderTemplate.recordId) return false
    if (
      !$scope.workorderTemplate.dueDateInHours ||
      $scope.workorderTemplate.dueDateInHours < 0
    ) {
      return false
    }
    if (
      _.isUndefined($scope.workorderTemplate.releasedBefore) ||
      $scope.workorderTemplate.releasedBefore === null ||
      $scope.workorderTemplate.releasedBefore < 0
    ) {
      return false
    }
    return true
  }

  const validNotifications = function validNotifications () {
    const mdToast = $mdToast.nextplus({
      position: $rootScope.toastLocation,
      parent: '#content',
      theme: 'error-toast',
      hideDelay: 2500
    })
    if (
      $scope.workorderTemplate.notifications &&
      $scope.workorderTemplate.notifications.length
    ) {
      for (let i = 0; i < $scope.workorderTemplate.notifications.length; i++) {
        const notification = $scope.workorderTemplate.notifications[i]
        if (!notification.type) {
          $mdToast.show(mdToast)
          $mdToast.updateTextContent(
            $translate.instant('WO.TEMPLATE.ERROR.NO_TYPE_SELECTED')
          )
          return false
        }
        if (
          notification.enabled &&
          (!notification.to || !notification.to.length)
        ) {
          $mdToast.show(mdToast)
          $mdToast.updateTextContent(
            $translate.instant('WO.TEMPLATE.ERROR.NO_USERS_FOR_NOTIFICATION')
          )
          return false
        }
      }
    }
    return true
  }

  $scope.save = async function save (
    workordersConfigurations = {},
    extraWorkorders = []
  ) {
    $rootScope.loadingProgress = true
    const isNotificationsValid = validNotifications()
    if (!isNotificationsValid) {
      $rootScope.loadingProgress = false
      return
    }
    try {
      const result = await Workorder.createMultipleWorkordersFromTemplate({
        template: _.omit($scope.workorderTemplate, ['createStock']),
        extraWorkorders,
        workordersConfigurations
      }).$promise
      const workorder = result.workorder
      WorkorderUtils.handleGeneratedSerialsErrors(
        result.generatedSerialsErrorsArray
      )
      $mdDialog.hide({ workorderId: workorder.id })
      $rootScope.loadingProgress = false
    } catch (err) {
      console.error(err)
      $rootScope.loadingProgress = false
      if (
        [
          'PHANTOM_PART_ERROR',
          'QUANTITY_INTEGER_UNIT_EACH',
          'QUANTITY_INTEGER_BULK_PRODUCTION',
          'QUANTITY_INTEGER_SUB_PARTS',
          'WORKORDER_NUMBER_UNIQUE_ERROR'
        ].includes(err.data?.error?.code)
      ) {
        $rootScope.showErrorToast(
          $translate.instant(
            'WO.TEMPLATE.ERROR.' + err.data.error.code,
            err.data.error.details
          ),
          false
        )
      } else {
        $rootScope.showErrorToast(
          err.data.error.message || 'WO.TEMPLATE.ERROR',
          false
        )
      }
    }
  }

  $scope.cancel = () => {
    $mdDialog.cancel()
  }
}

module.exports = WorkorderTemplateDialogController
