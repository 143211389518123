/* global angular */
'use strict'

/**
 * @ngInject
 */
function UserInviteController (
  $rootScope,
  $translate,
  $scope,
  $mdToast,
  $mdDialog,
  UserModel,
  ResolvedRoles
) {
  const vm = this
  vm.disableLocalUserCreation = $rootScope.appSettings?.disableLocalUserCreation
  // Initialize form fields
  vm.initFields = function () {
    vm.serverRoles = ResolvedRoles.roles.filter(
      role =>
        !role.name.includes('$authenticated') &&
        !role.name.includes('$everyone') &&
        !role.name.includes('$unauthenticated') &&
        !role.name.includes('$owner')
    )

    vm.inviteData = {
      role: '$operator', // Default role
      emails: ''
    }

    // Initialize email count
    vm.emailCount = 0

    // Create formly model
    const inviteFields = [
      {
        key: 'role',
        type: 'selectWithSearch',
        className: 'flex-100',
        templateOptions: {
          label: $translate.instant('USER.PERMISSIONS'),
          required: true,
          searchPlaceholder: 'Search...',
          options: vm.serverRoles.map(role => {
            role.id = role.name
            role.name = role.nicename
            role.notAnOption = role.disableUse
            return role
          }),
          enableRemoveDisabledSelected: true
        }
      },
      {
        key: 'emails',
        type: 'textarea',
        className: 'flex-100',
        templateOptions: {
          label: $translate.instant('USER.INVITE.EMAILS'),
          description: $translate.instant('USER.INVITE.EMAILS_DESCRIPTION'),
          required: true,
          rows: 10,
          placeholder: 'user1@example.com\nuser2@example.com',
          onBlur: updateEmailCount,
          onChange: updateEmailCount
        }
      }
    ]

    $scope.inviteFields = inviteFields

    // Initialize form if not already done
    if (!$scope.inviteForm) {
      $scope.inviteForm = {}
    }
  }

  // Update email count whenever textarea changes
  function updateEmailCount (value) {
    vm.emailCount = vm.parseEmails(value).length
  }

  // Parse emails from the textarea
  vm.parseEmails = function (emailText) {
    if (!emailText) return []

    // Split by newlines, commas, or semicolons
    const emailLines = emailText.split(/[\n,;]+/)

    // Trim whitespace and filter out empty lines
    const validEmails = emailLines
      .map(email => email.trim())
      .filter(email => email.length > 0)
      .filter(vm.isValidEmail)

    // Remove duplicates by converting to Set and back to Array
    return [...new Set(validEmails)]
  }

  // Validate email format
  vm.isValidEmail = function (email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    return emailRegex.test(email)
  }

  // Check if user already exists
  vm.checkExistingUser = async function (email) {
    try {
      const user = await UserModel.findOne({
        filter: {
          where: { email }
        }
      }).$promise
      return !!user
    } catch (err) {
      console.error('Error checking existing user:', err)
      return false
    }
  }

  // Perform invitation
  vm.invite = async function () {
    if (vm.disableLocalUserCreation) {
      $mdToast.show(
        $mdToast.nextplus({
          position: $rootScope.toastLocation,
          parent: '#content',
          theme: 'error-toast',
          hideDelay: 4000
        })
      )
      $mdToast.updateTextContent($translate.instant('USER.INVITE.DISABLED'))
      return
    }

    // Check if form is valid without assuming $valid property exists
    if ($scope.inviteForm && $scope.inviteForm.$valid === false) {
      return
    }

    const uniqueEmails = vm.parseEmails(vm.inviteData.emails)

    if (uniqueEmails.length === 0) {
      $mdToast.show(
        $mdToast.nextplus({
          position: $rootScope.toastLocation,
          parent: '#content',
          theme: 'error-toast',
          hideDelay: 4000
        })
      )
      $mdToast.updateTextContent(
        $translate.instant('USER.INVITE.NO_VALID_EMAILS')
      )
      return
    }

    $rootScope.loadingProgress = true
    const results = []
    const processedEmails = new Set()

    // Process each email
    for (const email of uniqueEmails) {
      // Skip if already processed in this batch
      if (processedEmails.has(email)) {
        continue
      }

      processedEmails.add(email)

      try {
        const userData = {
          email,
          username: email,
          firstName: '',
          lastName: '',
          roles: [vm.inviteData.role],
          setPassword: false,
          mediaAutoLoad: true,
          source: 'local'
        }

        await UserModel.createNewUser({ userData }).$promise
        results.push({
          email,
          success: true
        })
      } catch (err) {
        let errorMessage = 'Unknown error'

        if (err.data && err.data.error && err.data.error.message) {
          errorMessage = err.data.error.message
        } else if (err.message) {
          errorMessage = err.message
        }

        results.push({
          email,
          success: false,
          error: errorMessage
        })
      }
    }

    $rootScope.loadingProgress = false

    // Sort results: failures first, then successes, alphabetical within groups
    results.sort((a, b) => {
      if (a.success && !b.success) return 1
      if (!a.success && b.success) return -1
      return a.email.localeCompare(b.email)
    })

    // Show results in dialog
    vm.showResultsDialog(results)
  }

  // Display results dialog
  vm.showResultsDialog = function (results) {
    const successCount = results.filter(r => r.success).length
    const failCount = results.length - successCount

    $mdDialog.show({
      controller: 'InviteResultsDialogController',
      controllerAs: 'vm',
      template: require('./invite-results-dialog.html'),
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      locals: {
        results,
        successCount,
        failCount
      }
    })
  }

  // Form validation
  $scope.formIsValid = function () {
    if (!vm.inviteData.role || !vm.inviteData.emails) {
      return false
    }

    const emails = vm.parseEmails(vm.inviteData.emails)
    return emails.length > 0
  }

  // Set up header
  $scope.headerOptions = {
    icon: 'icon-account-plus',
    template: require('app/templates/headers/simple.html'),
    title: $translate.instant('USER.INVITE.TITLE')
  }

  // Initialize
  vm.initFields()

  // Set initial email count
  $scope.$watch('vm.inviteData.emails', function (newValue) {
    updateEmailCount(newValue)
  })
}

/**
 * @ngInject
 */
function InviteResultsDialogController (
  $mdDialog,
  results,
  successCount,
  failCount
) {
  const vm = this

  vm.results = results
  vm.successCount = successCount
  vm.failCount = failCount

  vm.close = function () {
    $mdDialog.hide()
  }
}

module.exports = {
  UserInviteController,
  InviteResultsDialogController
}
