/* global _ angular */
'use strict'
import AddEditShiftTypeController from './add-edit-shift-type/add-edit-shift-type.controller'
require('../shift.scss')
/** @ngInject */
function ShiftTypesController (
  $scope,
  $rootScope,
  $translate,
  PermissionUtils,
  KendoGridHelper,
  ViewsService,
  Page,
  ShiftType,
  $mdDialog,
  htmlWork,
  ShiftHelper
) {
  const hasCreatePermissions = PermissionUtils.isPermit('ShiftType', 'create')
  const hasEditPermissions = PermissionUtils.isPermit(
    'ShiftType',
    'patchAttributes'
  )
  const hasDeletePermissions = PermissionUtils.isPermit(
    'ShiftType',
    'deleteById'
  )
  $scope.deleteShiftType = function deleteShiftType (id, name) {
    $mdDialog
      .show(
        $mdDialog
          .confirm()
          .title(
            $translate.instant('SHIFT.SHIFT_TYPES.REAL_DELETE_SHIFT_TYPE', {
              name
            })
          )
          .clickOutsideToClose(true)
          .multiple(true)
          .parent(angular.element(document.body))
          .ok($translate.instant('BUTTONS.DELETE'))
          .cancel($translate.instant('BUTTONS.CANCEL'))
      )
      .then(
        function () {
          ShiftType.deleteById({ id })
            .$promise.then(() => {
              $rootScope.showSuccessToast(
                $translate.instant('SHIFT.SHIFT_TYPES.DELETE_SUCCESS')
              )
              $scope.kendoGrid.reloadData()
            })
            .catch(err => {
              console.error(err)
              $rootScope.showErrorToast('NP-8063')
              console.log(
                `An error occurred while trying to delete shift type '${id}'`,
                err
              )
            })
        },
        function () {}
      )
  }
  $scope.addEditShiftType = function addEditShiftType (id) {
    return $mdDialog
      .show({
        controller: AddEditShiftTypeController,
        template: require('./add-edit-shift-type/add-edit-shift-type.template.html'),
        parent: angular.element(document.body),
        targetEvent: '',
        resolve: {
          ResolvedShiftType: () => {
            if (id) {
              return ShiftType.findById({ id }).$promise
            }
            return null
          }
        }
      })
      .then(model => {
        $scope.kendoGrid.reloadData()
      })
  }
  const initScreen = async function initScreen () {
    $scope.title = $rootScope.title = $translate.instant(
      'SHIFT.SHIFT_TYPES.TITLE'
    )
    Page.setTitleText($scope.title)
    $scope.PermissionUtils = PermissionUtils

    const tableColumns = [
      // NAME
      {
        uniqueId: '30a6c803-c1a4-4f6e-bc65-4d5b78905059',
        field: 'name',
        translateCode: 'SHIFT.SHIFT_TYPES.FIELDS.NAME',
        trustedTemplate: data => {
          return ShiftHelper.generateTypeTemplate(
            data.name,
            data.foregroundColor,
            data.backgroundColor
          )
        }
        // media: '(min-width: 768px)'
      },
      // START_TIME
      {
        uniqueId: '44cb40be-e00b-425a-825e-7fe3519bfde1',
        field: 'startTime',
        translateCode: 'SHIFT.SHIFT_TYPES.FIELDS.START_TIME'
      },
      // END_TIME
      {
        uniqueId: '618f6445-e1c1-4a9c-ba1f-df0f5901c6a2',
        field: 'endTime',
        translateCode: 'SHIFT.SHIFT_TYPES.FIELDS.END_TIME'
      }
    ]

    tableColumns.push(
      // ACTIONS
      {
        uniqueId: '95839aee-ebfe-4569-9302-18732a0e9a69',
        field: 'id',
        translateCode: 'COMMON.ACTIONS',
        headerAttributes: {
          class: 'actions-column-header'
        },
        // media: '(min-width: 768px)',
        filterable: false,
        sortable: false,
        trustedTemplate: data => {
          const name = htmlWork.escapeHTMLQuotes(data.name)
          let html = `<div layout="row" layout-align="start center" style="direction: ${$rootScope.dir}">`
          if (hasEditPermissions) {
            html += `<md-button ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button','show-button']" data-testid="show-${data.id}" ng-click="addEditShiftType('${data.id}')">
                        <md-icon md-font-icon="icon-pencil" class="s20"></md-icon>
                      </md-button>`
          }
          if (hasDeletePermissions) {
            html += `<md-button ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini'] : ['md-icon-button']" class="md-warn"
                                          ng-click="deleteShiftType('${data.id}','${name}')" data-testid="delete-${data.id}">
                                <md-icon md-font-icon="icon-delete" class="s16"></md-icon>
                              </md-button>`
          }
          html += '</div>'
          return html
        }
      }
    )

    const { defaultTableColumns } = ViewsService.generateCustomFieldColumns(
      'ShiftType',
      tableColumns
    )

    const baseFilter = {
      where: {},
      order: 'modified DESC'
    }

    const { columns, selectedViewId } =
      ViewsService.getTablesColumns(defaultTableColumns)

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter)
    )

    const defaultTableSetup = {
      // stateName
      // ignoreParams
      find: ShiftType.find,
      count: ShiftType.count,
      cleanBaseFilter: baseFilter,
      baseFilter: newBaseFilter,
      selectedViewId,
      columns: defaultTableColumns
    }

    const defaultTableToolbarSetup = {
      // stateName
      columns: defaultTableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters,
      selectedViewId,
      title: $scope.title
    }

    $scope.kendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.kendoGrid.isBound()

    $scope.tableToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.kendoGrid,
      $scope
    )

    $scope.$applyAsync()
  }

  initScreen()

  $scope.headerOptions = {
    icon: 'icon-account',
    template: require('app/templates/headers/simple.html'),
    title: $scope.title,
    fabButton: {
      template: require('app/templates/headers/fab-button.html'),
      action: $scope.addEditShiftType,
      icon: 'icon-plus',
      href: '',
      state: '',
      showExpression: () => hasCreatePermissions
    }
  }
}

module.exports = ShiftTypesController
