/** @ngInject */
module.exports = function config (
  $stateProvider,
  msApiProvider,
  msNavigationServiceProvider
) {
  // State
  $stateProvider
    .state('app.playground', {
      url: '/playground',
      abstract: true,
      lazyLoad: $transition$ => {
        const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad')
        return import(
          /* webpackChunkName: "playground.module" */ './playground.module.js'
        )
          .then(mod => {
            return $ocLazyLoad.load(mod.default)
          })
          .catch(err => {
            throw new Error('Ooops, something went wrong, ' + err)
          })
      }
    })
    .state('app.playground.comments', {
      url: '/comments',
      views: {
        'content@app': {
          template: require('./comments/playground.comments.html'),
          controller: require('./comments/playground.comments.controller.js'),
          controllerAs: 'vm'
        }
      },
      resolve: {
        lazyload: $ocLazyLoad => {
          return import(
            /* webpackChunkName: "ChatModule" */ '../chat/chat.module.js'
          ).then(mod => {
            $ocLazyLoad.inject('chat', mod.default)
          })
        }
      }
    })
    .state('app.playground.tinymce', {
      url: '/tinymce',
      views: {
        'content@app': {
          template: require('./tinymce/playground.tinymce.html'),
          controller: require('./tinymce/playground.tinymce.controller.js'),
          controllerAs: 'vm'
        }
      },
      resolve: {}
    })

    .state('app.playground.asaf', {
      url: '/asaf',
      views: {
        'content@app': {
          template: require('./playground.asaf.html'),
          controller: ($scope, Fields, Users, Formdata) => {
            $scope.fields = Fields
            $scope.Formdata = Formdata
            $scope.users = Users
          },
          controllerAs: 'vm'
        }
      },
      resolve: {
        Fields: Field => Field.find({ filter: { deleted: true } }).$promise,
        Users: UserModel =>
          UserModel.find({
            filter: { fields: { id: true, displayName: true } }
          }).$promise.then(res =>
            res.reduce((obj, user) => {
              obj[user.id] = user.displayName
              return obj
            }, {})
          ),
        Formdata: FormData =>
          FormData.findOne({
            filter: { where: { id: '56425bd0-ede3-11ea-940d-4bbc3c95bb95' } }
          }).$promise
      },
      bodyClass: 'asaf'
    })
    .state('app.playground.allfields', {
      url: '/allfields',
      views: {
        'content@app': {
          template: require('./playground.allfields.html'),
          controller: 'PlaygroundAllFieldsController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'allfields'
    })
    .state('app.playground.askai', {
      url: '/askai',
      views: {
        'content@app': {
          template: require('./playground.askai.html'),
          controller: 'PlaygroundASKAIController',
          controllerAs: 'vm'
        }
      },
      resolve: {
        lazyload: $ocLazyLoad => {
          return import(
            /* webpackChunkName: "ChatModule" */ '../chat/chat.module.js'
          ).then(mod => {
            $ocLazyLoad.inject('chat', mod.default)
          })
        }
      },
      bodyClass: 'askai'
    })

  // Navigation
  msNavigationServiceProvider.saveItem('apps.playground', {
    title: 'Playgrounds',
    icon: 'icon-seesaw',
    state: 'app.playground.show',
    weight: 1,
    hidden: true,
    acls: []
  })
  msNavigationServiceProvider.saveItem('apps.playground.tinymce', {
    title: 'TinyMCE',
    state: 'app.playground.tinymce',
    weight: 1,
    acls: []
  })
  msNavigationServiceProvider.saveItem('apps.playground.comments', {
    title: 'comments',
    state: 'app.playground.comments',
    weight: 1,
    acls: []
  })

  msNavigationServiceProvider.saveItem('apps.playground.asaf', {
    title: 'Asaf',
    state: 'app.playground.asaf',
    weight: 1,
    acls: []
  })
  msNavigationServiceProvider.saveItem('apps.playground.allfields', {
    title: 'All Fields',
    state: 'app.playground.allfields',
    weight: 1,
    acls: []
  })
  msNavigationServiceProvider.saveItem('apps.playground.askai', {
    title: 'AskAI',
    state: 'app.playground.askai',
    weight: 1,
    acls: []
  })
}
