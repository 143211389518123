require('./comment-wrapper.scss')
const commentWrapper = {
  bindings: {
    comments: '<',
    commentModel: '=',
    parentId: '='
  },
  template: require('./comment-wrapper.html'),
  /** @ngInject */
  controller: function ($scope, $window) {
    const $ctrl = this
    const SORT_KEY = 'comment_sort_order'

    // Initialize sort order from localStorage or default to 'desc'
    $ctrl.sortOrder = $window.localStorage.getItem(SORT_KEY) || 'desc'

    $ctrl.toggleSort = function () {
      $ctrl.sortOrder = $ctrl.sortOrder === 'desc' ? 'asc' : 'desc'
      $window.localStorage.setItem(SORT_KEY, $ctrl.sortOrder)
      $ctrl.sortComments()
    }

    $ctrl.sortComments = function () {
      if (!$ctrl.comments) return

      $ctrl.comments.sort((a, b) => {
        const dateA = new Date(a.created)
        const dateB = new Date(b.created)
        return $ctrl.sortOrder === 'desc' ? dateB - dateA : dateA - dateB
      })
    }

    $ctrl.onCreate = function (comment) {
      if ($ctrl.sortOrder === 'desc') {
        $ctrl.comments.unshift(comment)
      } else {
        $ctrl.comments.push(comment)
      }
      $scope.$applyAsync()
    }

    this.$onInit = async function () {
      $ctrl.sortComments()
    }

    this.$onChanges = async function (changes) {
      if (changes.comments && changes.comments.currentValue) {
        $ctrl.sortComments()
      }
    }

    this.$onDestroy = function () {}
  }
}
module.exports = commentWrapper
