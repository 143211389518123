/* global kendo _ angular */
import ProductionEntitySplitController from './split-event/split-event.controller'
import CheckInCheckOutDialogController from './check-in-check-out/check-in-check-out.dialog.controller'
import FormReviewController from '../../form/review/form-review.controller'
import ProductionEntityFormsDialogController from './form/production-entity.forms.controller'
require('./production-entity-view-page.scss')
/** @ngInject */
function ProductionEntityViewController (
  ResolvedProductionEntity,
  ProductionEntity,
  $mdDialog,
  $translate,
  $mdToast,
  $scope,
  ProductionEntityHelper,
  PermissionUtils,
  ProductionEntityEvent,
  ViewsService,
  KendoGridHelper,
  DateTimeFormatService,
  $rootScope,
  htmlWork,
  FormUtils,
  Form,
  ProductionEntityTransaction,
  ResolvedUsers,
  $mdSelect
) {
  const vm = this
  vm.productionEntity = ResolvedProductionEntity
  vm.parameterValues = []
  vm.isRefreshingParameters = false

  vm.usersById = _.keyBy(ResolvedUsers, 'id')

  vm.formatDateTime = function (date) {
    return DateTimeFormatService.formatDateTime(date, 'dateTime')
  }

  vm.canChangeStatus = PermissionUtils.isPermit(
    'ProductionEntity',
    'patchAttributes'
  )

  // Prepare statuses with their colors
  vm.availableStatuses = vm.productionEntity.statuses
    .filter(status => !status.deletedAt)
    .map(status => ({
      id: status.id,
      name: status.name,
      color: status.color || '#ffffff'
    }))

  vm.onStatusChange = function (newStatusId) {
    const newStatus = vm.availableStatuses.find(s => s.id === newStatusId)
    if (newStatus) {
      // Store the current status in case we need to revert
      const currentStatusId = vm.productionEntity.statusId.toString()

      vm.changeStatus(newStatus.id, newStatus.name).catch(() => {
        // Revert the model if the server update fails
        vm.productionEntity.statusId = currentStatusId
        $scope.$apply()
      })
    }
  }

  const hasSplitPermissions = PermissionUtils.isPermit(
    'ProductionEntityEvent',
    'splitEvent'
  )
  const availableEventTypes = [
    {
      value: 'PARAMETER_SAMPLE',
      text: $translate.instant('PRODUCTION_ENTITY.EVENT_TYPES.PARAMETER_SAMPLE')
    },
    {
      value: 'STATUS_CHANGE',
      text: $translate.instant('PRODUCTION_ENTITY.EVENT_TYPES.STATUS_CHANGE')
    },
    {
      value: 'TRIGGER_ACTION',
      text: $translate.instant('PRODUCTION_ENTITY.EVENT_TYPES.TRIGGER_ACTION')
    }
  ]
  const availableTransactionTypes = [
    {
      value: 'CHECK_IN',
      text: $translate.instant('PRODUCTION_ENTITY.TRANSACTION_TYPES.CHECK_IN')
    },
    {
      value: 'CHECK_OUT',
      text: $translate.instant('PRODUCTION_ENTITY.TRANSACTION_TYPES.CHECK_OUT')
    }
  ]

  vm.refreshParameters = async function refreshParameters () {
    try {
      vm.isRefreshingParameters = true
      vm.parameterValues = await ProductionEntity.prototype$getParameterValues({
        id: vm.productionEntity.id
      }).$promise
    } catch (error) {
      $rootScope.showErrorToast(
        $translate.instant('PRODUCTION_ENTITY.ERRORS.GENERAL_ERROR')
      )
    } finally {
      vm.isRefreshingParameters = false
      $scope.$applyAsync()
    }
  }

  vm.getStatusTemplate = function () {
    const statusId = vm.productionEntity.statusId
    const statusObj = vm.productionEntity.statuses.find(s => s.id === statusId)
    return ProductionEntityHelper.generateStatusTemplate(
      statusObj?.name,
      statusObj?.color ? statusObj.color : '#ffffff'
    )
  }

  vm.changeStatus = function (statusId, statusName) {
    const confirm = $mdDialog
      .confirm()
      .title($translate.instant('PRODUCTION_ENTITY.CHANGE_STATUS'))
      .textContent(
        $translate.instant('PRODUCTION_ENTITY.CHANGE_STATUS_MESSAGE', {
          name: vm.productionEntity.name,
          status: statusName
        })
      )
      .ariaLabel('Change Status')
      .ok($translate.instant('BUTTONS.OK'))
      .cancel($translate.instant('BUTTONS.CANCEL'))

    return $mdDialog
      .show(confirm)
      .then(() => {
        vm.loading = true
        return ProductionEntity.changeStatus({
          id: vm.productionEntity.id,
          statusId
        })
          .$promise.then(() => {
            vm.loading = false
            vm.productionEntity.status = statusName
            vm.productionEntity.statusId = statusId
            $scope.eventsKendoGrid.reloadData()
            vm.refreshParameters()
            $rootScope.showSuccessToast('Status changed successfully')

            // Close the select using $mdSelect service
            $mdSelect.hide()
          })
          .catch(error => {
            let errorMessage = $translate.instant(
              'PRODUCTION_ENTITY.ERRORS.GENERAL_ERROR'
            )
            if (
              [
                'PRODUCTION_ENTITY_NOT_FOUND',
                'STATUS_NOT_FOUND',
                'STATUS_ALREADY_SET',
                'STATUS_DELETED'
              ].includes(error?.data?.error?.code)
            ) {
              errorMessage = $translate.instant(
                `PRODUCTION_ENTITY.ERRORS.${error?.data?.error?.code}`
              )
            }
            vm.loading = false
            console.error('Error changing status:', error)
            $rootScope.showErrorToast(errorMessage)
            vm.currentStatusId = vm.productionEntity.statusId
            throw error // Propagate the error to trigger the revert
          })
      })
      .catch(error => {
        // If user cancels the dialog, revert the model
        vm.currentStatusId = vm.productionEntity.statusId
        throw error
      })
  }

  vm.splitEvent = function (eventId) {
    return $mdDialog
      .show({
        controller: ProductionEntitySplitController,
        template: require('./split-event/split-event.template.html'),
        parent: angular.element(document.body),
        targetEvent: '',
        clickOutsideToClose: true,
        locals: {
          statuses: vm.productionEntity.statuses
        },
        resolve: {
          ResolvedProductionEntityEvent: function () {
            return ProductionEntityEvent.findById({ id: eventId }).$promise
          }
        }
      })
      .then(() => {
        $scope.eventsKendoGrid.reloadData()
      })
  }

  $scope.checkInCheckOut = function checkInCheckOut () {
    return $mdDialog
      .show({
        controller: CheckInCheckOutDialogController,
        template: require('./check-in-check-out/check-in-check-out.dialog.template.html'),
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        multiple: true,
        resolve: {
          ResolvedSessions: function () {
            return ProductionEntity.getProductionEntityRelatedSessions({
              productionEntityId: vm.productionEntity.id,
              productionEntityTypeId: vm.productionEntity.productionEntityTypeId
            }).$promise
          },
          ResolvedProductionEntity: function () {
            return vm.productionEntity
          }
        }
      })
      .finally(() => {
        vm.refreshParameters()
      })
  }
  vm.openNewForm = function openNewForm (eventId) {
    if (vm.forms.length === 1) {
      return vm.openForm(eventId, vm.forms[0].id)
    }
    return $mdDialog
      .show({
        controller: ProductionEntityFormsDialogController,
        template: require('./form/production-entity.forms.template.html'),
        parent: angular.element(document.body),
        clickOutsideToClose: true,
        locals: {
          forms: vm.forms
        }
      })
      .then(({ formId }) => {
        vm.openForm(eventId, formId)
      })
  }
  vm.openForm = function openForm (eventId, formId = null, formDataId = null) {
    return $mdDialog
      .show({
        resolve: {
          lazyLoad: $ocLazyLoad => {
            return import(
              /* webpackChunkName: "form.module" */ '../../form/form.module.js'
            )
              .then(mod => {
                return $ocLazyLoad.inject(mod.default)
              })
              .catch(err => {
                throw new Error('Ooops, something went wrong, ' + err)
              })
          },
          sessionData: () => {
            return {
              productionEntityId: vm.productionEntity.id,
              productionEntityEventId: eventId
            }
          },
          isDisabled: () => false,
          ResolvedData: (
            FormData,
            Field,
            $rootScope,
            MultiTranslateService
          ) => {
            return new Promise(async (resolve, reject) => {
              let fields = []
              if (!formDataId || formDataId === '') {
                const form = vm.formsById[formId]
                form.fieldSettings =
                  form?.fieldSettings && typeof form.fieldSettings === 'string'
                    ? JSON.parse(form.fieldSettings)
                    : typeof form.fieldSettings === 'object'
                    ? form.fieldSettings
                    : {}
                form.fields = form.fields.filter(field => !field.unused)
                const fieldIds = form.fields.map(formField => formField.fieldId)
                fields = await Field.getFieldsWithInnerFields({
                  fieldIds
                }).$promise
                fields = fields.map(field =>
                  MultiTranslateService.getForView(
                    Field,
                    $rootScope.currentLang,
                    field
                  )
                )
                resolve({
                  formDatas: [],
                  users: [],
                  form,
                  fields,
                  workflows: []
                })
              } else {
                try {
                  const result = await FormData.findInstance({
                    where: { id: formDataId }
                  }).$promise
                  const { users, formData, workflows, form } = result
                  fields = result.fields.map(field =>
                    MultiTranslateService.getForView(
                      Field,
                      $rootScope.currentLang,
                      field
                    )
                  )

                  resolve({
                    formDatas: [formData],
                    users,
                    form,
                    fields,
                    workflows
                  })
                } catch (err) {
                  reject(err)
                }
              }
            })
          },
          ResolvedForms: Form =>
            Form.find({
              filter: {
                where: {
                  isTemplate: false
                }
              }
            }).$promise,
          parentLinkedForm: () => null
        },
        controller: FormReviewController,
        template: require('../../workflow/show/dialogs/workflow.form-review.template.html'),
        fullscreen: true,
        parent: angular.element(document.body),
        targetEvent: '',
        multiple: true,
        clickOutsideToClose: false
      })
      .then(async formDatas => {
        $scope.eventsKendoGrid.reloadData()
      })
  }

  const initEventsTable = async function initEventsTable () {
    const tableColumns = [
      // TYPE
      {
        uniqueId: '2gf3h4j5-8957-4a4d-9745-d1e0428dc773',
        field: 'type',
        translateCode: 'PRODUCTION_ENTITY.EVENT_FIELDS.TYPE',
        values: availableEventTypes,
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            operator: 'eq',
            suggestionOperator: 'eq',
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'text',
                dataValueField: 'value',
                dataSource: new kendo.data.DataSource({
                  data: availableEventTypes
                }),
                valuePrimitive: true
              })
            }
          }
        }
      },
      // START
      {
        uniqueId: 'g2h3j4k5-8957-4a4d-9745-d1e0428dc773',
        field: 'start',
        translateCode: 'PRODUCTION_ENTITY.EVENT_FIELDS.START',
        type: 'date',
        template: data => {
          return data.start === null
            ? '--'
            : DateTimeFormatService.formatDateTime(data.start, 'dateTime')
        }
      },
      // END
      {
        uniqueId: 'a2s3d4f5-8957-4a4d-9745-d1e0428dc773',
        field: 'end',
        translateCode: 'PRODUCTION_ENTITY.EVENT_FIELDS.END',
        type: 'date',
        template: data => {
          return data.end === null
            ? '--'
            : DateTimeFormatService.formatDateTime(data.end, 'dateTime')
        }
      },
      // PARAMETER NAME
      {
        uniqueId: 't2y3u4i5-8957-4a4d-9745-d1e0428dc773',
        field: 'parameterName',
        translateCode: 'PRODUCTION_ENTITY.EVENT_FIELDS.PARAMETER_NAME'
      },
      // PARAMETER VALUE STRING
      {
        uniqueId: 'o2p3l4k5-8957-4a4d-9745-d1e0428dc773',
        field: 'parameterValueString',
        translateCode: 'PRODUCTION_ENTITY.EVENT_FIELDS.PARAMETER_VALUE_STRING',
        type: 'string',
        template: data => {
          return data.parameterValueString ? data.parameterValueString : '--'
        }
      },
      // PARAMETER VALUE NUMBER
      {
        uniqueId: 'de053c2e-b6d9-4fe6-92fd-95e16b0bf197',
        field: 'parameterValueNumber',
        translateCode: 'PRODUCTION_ENTITY.EVENT_FIELDS.PARAMETER_VALUE_NUMBER',
        type: 'number',
        template: data => {
          return data.parameterValueNumber ? data.parameterValueNumber : '--'
        }
      },
      // PARAMETER VALUE BOOLEAN
      {
        uniqueId: '6e6b7c44-3a0a-4c10-b714-426c5a7f4b13',
        field: 'parameterValueBoolean',
        translateCode: 'PRODUCTION_ENTITY.EVENT_FIELDS.PARAMETER_VALUE_BOOLEAN',
        type: 'boolean'
      },
      // PARAMETER VALUE DATE
      {
        uniqueId: '63fc6778-0383-46fa-adc7-db3286b3eb9c',
        field: 'parameterValueDate',
        translateCode: 'PRODUCTION_ENTITY.EVENT_FIELDS.PARAMETER_VALUE_DATE',
        type: 'date',
        template: data => {
          return data.parameterValueDate
            ? DateTimeFormatService.formatDateTime(
                data.parameterValueDate,
                'dateTime'
              )
            : '--'
        }
      },
      // WORKORDERS
      {
        uniqueId: 'c3b54b6f-9c8e-4c41-9f99-9f715bb14fb8',
        field: 'items.workorderNumber',
        type: 'array',
        translateCode: 'PRODUCTION_ENTITY.EVENT_FIELDS.WORKORDERS',
        trustedTemplate: data => {
          return data.items?.length > 0
            ? data.items
                ?.map(item => {
                  return `<a ui-sref="app.workorder.show({id: '${htmlWork.htmlEncode(
                    item.workorderId
                  )}'})" target="_blank">${htmlWork.htmlEncode(
                    item.workorderNumber
                  )}</a>`
                })
                .join(', ')
            : '--'
        }
      },
      // SKUS
      {
        uniqueId: '5a700479-fe51-433f-9fec-3cc109f7bc8d',
        field: 'items.sku',
        type: 'array',
        translateCode: 'PRODUCTION_ENTITY.EVENT_FIELDS.SKUS',
        template: data => {
          return data.items?.length > 0
            ? data.items.map(item => item.sku).join(', ')
            : '--'
        }
      },
      // SERIALS
      {
        uniqueId: '7aecb583-4974-41dc-8b6c-37921a78348e',
        field: 'items.serial',
        type: 'array',
        translateCode: 'PRODUCTION_ENTITY.EVENT_FIELDS.SERIALS',
        trustedTemplate: data => {
          return data.items?.length > 0
            ? data.items
                .filter(item => item.serial)
                .map(item => {
                  return `<a ui-sref="app.devices.show({id: '${htmlWork.htmlEncode(
                    item.stockId
                  )}'})" target="_blank">${htmlWork.htmlEncode(
                    item.serial
                  )}</a>`
                })
                .join(', ')
            : '--'
        }
      },
      // FORMS
      {
        uniqueId: 'q2w3e4r5-8957-4a4d-9745-d1e0428dc773',
        field: 'forms.stringNumber',
        translateCode: 'PRODUCTION_ENTITY.EVENT_FIELDS.FORMS',
        trustedTemplate: data => {
          if (data.forms?.length > 0) {
            return data.forms
              .map(formData => {
                return `<a style="cursor: pointer" ng-click="vm.openForm('${htmlWork.htmlEncode(
                  data.id
                )}', null, '${htmlWork.htmlEncode(
                  formData.id
                )}')">#${htmlWork.htmlEncode(formData.number)}</a>`
              })
              .join(', ')
          }
          return '--'
        }
      },
      // ACTIONS
      {
        uniqueId: 'z2x3c4v5-8957-4a4d-9745-d1e0428dc773',
        field: 'id',
        translateCode: 'COMMON.ACTIONS',
        headerAttributes: {
          class: 'actions-column-header'
        },
        // media: '(min-width: 768px)',
        filterable: false,
        sortable: false,
        trustedTemplate: data => {
          let html = `<div layout="row" layout-align="start center" style="direction: ${$rootScope.dir}">`
          if (
            hasSplitPermissions &&
            data.end !== null &&
            data.type === 'STATUS_CHANGE'
          ) {
            html += `<md-button ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button','show-button']"
                     data-testid="view-${htmlWork.htmlEncode(data.id)}"
                     ng-click="vm.splitEvent('${htmlWork.htmlEncode(
                       data.id
                     )}')">
                      <md-icon md-font-icon="icon-arrow-split-vertical" class="s20"></md-icon>
                    </md-button>`
          }
          if (vm.forms.length > 0) {
            html += `
              <md-button ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button','show-button']"
                data-testid="open-form-${htmlWork.htmlEncode(data.id)}"
                ng-click="vm.openNewForm('${htmlWork.htmlEncode(data.id)}')">
                <md-icon md-font-icon="icon-clipboard-plus-outline" class="icon"></md-icon>
              </md-button>
            `
          }
          html += '</div>'
          return html
        }
      }
    ]

    await initTableByModel(ProductionEntityEvent, tableColumns)
  }

  const initTransactionsTable = async function initTransactionsTable () {
    const tableColumns = [
      // USER
      {
        uniqueId: '926de430-41a2-4687-ba6e-2595ab9e86bd',
        field: 'userId',
        translateCode: 'PRODUCTION_ENTITY.TRANSACTION_FIELDS.USER',
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            operator: 'eq',
            suggestionOperator: 'eq',
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'displayName',
                dataValueField: 'id',
                dataSource: new kendo.data.DataSource({
                  data: ResolvedUsers
                }),
                valuePrimitive: true
              })
            }
          }
        },
        trustedTemplate: data => {
          if (!data.userId || !vm.usersById[data.userId]) return '--'
          return `<user-avatar
                    user-id="'${htmlWork.escapeHTMLQuotes(data.userId)}'"
                    display-name="vm.usersById['${htmlWork.escapeHTMLQuotes(
                      data.userId
                    )}'].displayName"
                    show-name-inline="true"
                  ></user-avatar>`
        }
      },
      // TYPE
      {
        uniqueId: '826de430-41a2-4687-ba6e-2595ab9e86bd',
        field: 'type',
        translateCode: 'PRODUCTION_ENTITY.TRANSACTION_FIELDS.TYPE',
        values: availableTransactionTypes,
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            operator: 'eq',
            suggestionOperator: 'eq',
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'text',
                dataValueField: 'value',
                dataSource: new kendo.data.DataSource({
                  data: availableTransactionTypes
                }),
                valuePrimitive: true
              })
            }
          }
        }
      },
      // QUANTITY
      {
        uniqueId: '726de430-41a2-4687-ba6e-2595ab9e86bd',
        field: 'quantity',
        translateCode: 'PRODUCTION_ENTITY.TRANSACTION_FIELDS.QUANTITY',
        type: 'number'
      },
      // WORKORDER
      {
        uniqueId: '626de430-41a2-4687-ba6e-2595ab9e86bd',
        field: 'workorderNumber',
        translateCode: 'PRODUCTION_ENTITY.TRANSACTION_FIELDS.WORKORDER',
        trustedTemplate: data => {
          return data.workorderNumber
            ? `<a ui-sref="app.workorder.show({id: '${htmlWork.htmlEncode(
                data.workorderId
              )}'})" target="_blank">${htmlWork.htmlEncode(
                data.workorderNumber
              )}</a>`
            : '--'
        }
      },
      // SKU
      {
        uniqueId: '526de430-41a2-4687-ba6e-2595ab9e86bd',
        field: 'sku',
        translateCode: 'PRODUCTION_ENTITY.TRANSACTION_FIELDS.SKU'
      },
      // SERIAL
      {
        uniqueId: '426de430-41a2-4687-ba6e-2595ab9e86bd',
        field: 'serial',
        translateCode: 'PRODUCTION_ENTITY.TRANSACTION_FIELDS.SERIAL',
        trustedTemplate: data => {
          return data.serial
            ? `<a ui-sref="app.devices.show({id: '${htmlWork.htmlEncode(
                data.stockId
              )}'})" target="_blank">${htmlWork.htmlEncode(data.serial)}</a>`
            : '--'
        }
      }
    ]
    await initTableByModel(ProductionEntityTransaction, tableColumns)
  }

  const initTableByModel = async function initTableByModel (
    model,
    tableColumns
  ) {
    const tableName =
      model.modelName === 'ProductionEntityTransaction'
        ? 'transactions'
        : 'events'
    const baseFilter = {
      where: { productionEntityId: vm.productionEntity.id },
      order: 'start DESC'
    }

    const { defaultTableColumns } = ViewsService.generateCustomFieldColumns(
      model.modelName,
      tableColumns
    )

    const { columns, selectedViewId } =
      ViewsService.getTablesColumns(defaultTableColumns)

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter)
    )

    const defaultTableSetup = {
      stateName: `app.production-entity.view.${tableName}`,
      // ignoreParams: false,
      find: model.find,
      count: model.count,
      cleanBaseFilter: baseFilter || {},
      baseFilter: newBaseFilter,
      selectedViewId,
      columns: defaultTableColumns,
      encodeTitles: true,
      pageSize: 10,
      autoSize: false
    }
    const defaultTableToolbarSetup = {
      stateName: `app.production-entity.view.${tableName}`,
      columns: defaultTableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters,
      selectedViewId,
      title: $scope.title
    }

    $scope[`${tableName}KendoGrid`] = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope[`${tableName}KendoGrid`].isBound()

    $scope[`${tableName}TableToolbar`] = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope[`${tableName}KendoGrid`],
      $scope
    )
  }

  const initScreen = async function initScreen () {
    vm.currentStatusId = vm.productionEntity.statusId
    await vm.refreshParameters()
    const forms = await Form.find({
      filter: {
        where: {
          and: [
            { context: 'productionEntity' },
            {
              or: [
                { productionEntityTypes: vm.productionEntity.entityType.id },
                { productionEntityTypes: { exists: false } },
                { productionEntityTypes: [] }
              ]
            }
          ]
        }
      }
    }).$promise
    const weightForms = _.sortBy(
      forms.filter(form => form.weight !== null && form.weight !== undefined),
      'weight'
    )

    // Sort forms without weight by name
    const nonWeightForms = _.sortBy(
      forms.filter(form => form.weight === null || form.weight === undefined),
      form => form.name.toLowerCase()
    )

    vm.forms = weightForms.concat(nonWeightForms).map(form => {
      form.hasPermission = FormUtils.isPermit('view', form)
      return form
    })
    vm.formsById = _.keyBy(vm.forms, 'id')
    await initEventsTable()
    await initTransactionsTable()

    $scope.$apply()
  }

  initScreen()

  // Set up header options
  $scope.headerOptions = {
    template: require('app/templates/headers/simple.html'),
    title: $translate.instant('PRODUCTION_ENTITY.VIEW_PAGE_TITLE', {
      name: vm.productionEntity.name
    }),
    fabButton: {
      template: `<div layout="row" layout-align="end center">
        <md-button class="md-raised md-white" ng-click="checkInCheckOut()" data-testid="check-in-check-out">
          <md-icon md-font-icon="icon-swap-horizontal" class="s18 mr-8"></md-icon>
          <span translate="PRODUCTION_ENTITY.CHECK_IN_CHECK_OUT"></span>
        </md-button>
      </div>`
    }
  }
}

module.exports = ProductionEntityViewController
