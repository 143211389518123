/* global _ angular libphonenumber */
'use strict'
import { isNilOrEmpty } from 'app/helper'
const timezones = require('../../../../../app/components/workorder-template/time-zones.json')
const UUID = require('uuid/v4')

/** @ngInject */

function UserModelShowController (
  $rootScope,
  $translate,
  $scope,
  $state,
  $stateParams,
  $mdToast,
  $window,
  UserModel,
  ResolvedUser,
  ResolvedRoles,
  ResolvedStations,
  PermissionUtils,
  ErrorHandlerService,
  Location,
  Group,
  FormlyHelper,
  DateTimeFormatService
) {
  const vm = this
  const successMdToast = $mdToast.nextplus({
    position: $rootScope.toastLocation,
    parent: '#content',
    theme: 'success-toast',
    hideDelay: 4000
  })

  const errorMdToast = $mdToast.nextplus({
    position: $rootScope.toastLocation,
    parent: '#content',
    theme: 'error-toast',
    hideDelay: 4000
  })
  const frequency = ['Daily', 'Weekly', 'Monthly', 'Yearly']
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ]

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  const initStationsOptions = function initStationsOptions () {
    vm.stations = ResolvedStations
    vm.stations.unshift({
      id: null,
      name: ''
    })
  }

  const errorHandler = function licenseErrorHandler (err) {
    $rootScope.loadingProgress = false
    const {
      data: {
        error: { code, details }
      }
    } = err
    if (
      code === 'LICENSE_ERROR' ||
      code === 'LICENSE_EXPIRED' ||
      code === 'LICENSE_ERROR_PER_ROLE'
    ) {
      const mdToast = $mdToast.nextplus({
        position: $rootScope.toastLocation,
        parent: 'body',
        theme: 'error-toast',
        hideDelay: 6000
      })
      $mdToast.show(mdToast)
      let availableCount = 0
      let maxAllowed = 0
      if (code === 'LICENSE_ERROR_PER_ROLE') {
        const { roleType, licenseSetting, userCalculation } =
          details.codes[code]
        if (roleType === 'editor') {
          availableCount = userCalculation.editorCount
          maxAllowed = licenseSetting.maxEditorCount
        } else {
          availableCount = userCalculation.viewerCount
          maxAllowed = licenseSetting.maxViewerCount
        }
        $mdToast.updateTextContent(
          $translate.instant(`USER.LICENSE.${code}`, {
            availableCount,
            maxAllowed,
            roleType
          })
        )
      } else if (code === 'LICENSE_ERROR') {
        availableCount = details.codes[code].dirtyUserCount
        maxAllowed = details.codes[code].licenseSetting.maxUserCount
        $mdToast.updateTextContent(
          $translate.instant(`USER.LICENSE.${code}`, {
            availableCount,
            maxAllowed
          })
        )
      } else {
        if (details.codes[code].licenseSetting.expirationDate)
          details.codes[code].licenseSetting.expirationDate =
            DateTimeFormatService.formatDateTime(
              details.codes[code].licenseSetting.expirationDate,
              'date'
            )
        $mdToast.updateTextContent(
          $translate.instant(`USER.LICENSE.${code}`, details.codes[code])
        )
      }
    } else {
      ErrorHandlerService.formlyErrorHandler(
        err,
        $scope.userFields,
        $scope.userForm
      )
    }
  }

  $scope.activateUser = async function activateUser () {
    try {
      await UserModel.activateUser({ id: $scope.user.id }).$promise
      $mdToast.show(successMdToast)
      $mdToast.updateTextContent($translate.instant('USER.SAVE_SUCCESS'))
      $scope.user.active = true
    } catch (err) {
      console.error(err)
      $mdToast.show(errorMdToast)
      errorHandler(err)
    }
  }

  $scope.deActivateUser = async function deActivateUser () {
    try {
      await UserModel.deActivateUser({ id: $scope.user.id }).$promise
      $mdToast.show(successMdToast)
      $mdToast.updateTextContent($translate.instant('USER.SAVE_SUCCESS'))
      $scope.user.active = false
    } catch (err) {
      console.error(err)
      $rootScope.showErrorToast('NP-7020')
    }
  }

  $scope.resetUserPassword = async function resetUserPassword () {
    try {
      await UserModel.resetUserPassword({ id: $scope.user.id }).$promise
      $mdToast.show(successMdToast)
      $mdToast.updateTextContent($translate.instant('USER.REQUEST_SENT'))
    } catch (err) {
      console.error(err)
      $rootScope.showErrorToast('NP-7030')
    }
  }

  $scope.formIsValid = function formIsValid () {
    if (
      isNilOrEmpty('email') ||
      isNilOrEmpty('username') ||
      isNilOrEmpty('firstName') ||
      isNilOrEmpty('lastName') ||
      ($scope.havePermission && isNilOrEmpty('roles')) ||
      (!vm.edit && $scope.user.setPassword && isNilOrEmpty('password'))
    ) {
      return false
    }
    return true
  }

  vm.save = function save () {
    $rootScope.loadingProgress = true
    delete $scope.user.fakeusername
    delete $scope.user.fakepassword
    if (_.isUndefined($scope.user.password) || $scope.user.password === '') {
      delete $scope.user.password
    }
    const tmpUser = angular.copy($scope.user)
    delete tmpUser.profileResource
    let phoneIsValid = true
    if (tmpUser.phone) {
      const phoneNumber = libphonenumber.parsePhoneNumberFromString(
        tmpUser.phone.split('_').join('')
      )
      if (!phoneNumber.isValid()) {
        $rootScope.loadingProgress = false
        phoneIsValid = false
        $mdToast.show(errorMdToast)
        $mdToast.updateTextContent(
          $translate.instant('USER.ERRORS.PHONE_MUST_VALID')
        )
      }
    }
    if (phoneIsValid) {
      if (vm.edit) {
        // prevent overwrite user preferences
        delete tmpUser.roles
        delete tmpUser.userPreferences
        delete tmpUser.passwordHistory
        delete tmpUser.resetPassword
        delete tmpUser.blockUntil
        UserModel.prototype$patchAttributes({ id: tmpUser.id }, tmpUser)
          .$promise.then(async userDetails => {
            if (tmpUser.id === $rootScope.currentUser.id) {
              // CHANGE local user groups
              $rootScope.currentUser.groups = userDetails.groups
            }
            try {
              if (vm.updateRolesFlag) {
                vm.updateRolesFlag = false
                await UserModel.updateUserRoles({
                  id: tmpUser.id,
                  roles: $scope.user.roles
                }).$promise
              }
              $mdToast.show(successMdToast)
              $mdToast.updateTextContent(
                $translate.instant('USER.SAVE_SUCCESS')
              )
            } catch (err) {
              errorHandler(err)
            }
            $rootScope.loadingProgress = false
            $state.go('app.user.list')
          })
          .catch(res => {
            errorHandler(res)
          })
      } else {
        tmpUser.lastTimeRecordsApproval = new Date()
        tmpUser.source = 'local'
        const promise = UserModel.createNewUser({ userData: tmpUser }).$promise
        promise
          .then(function (res) {
            if (res) {
              $rootScope.loadingProgress = false
              $state.go('app.user.list')
            }
          })
          .catch(res => {
            errorHandler(res)
          })
      }
    }
  }

  $window.secret = function secret () {
    $scope.userFields[0].fieldGroup.push({
      key: 'internal',
      type: 'checkbox',
      className: 'flex-20',
      templateOptions: {
        label: 'Internal',
        onChange: function (value, options) {
          options.validation.errorExistsAndShouldBeVisible = null
          options.formControl.$setValidity('serverError', true)
        }
      }
    })
    $scope.$applyAsync()
  }

  vm.load = function load () {
    vm.passwordMinLength = 8
    vm.serverRoles = ResolvedRoles.roles
    vm.serverRoles = vm.serverRoles.filter(
      role =>
        !role.name.includes('$authenticated') &&
        !role.name.includes('$everyone') &&
        !role.name.includes('$unauthenticated') &&
        !role.name.includes('$owner')
    )
    if (vm.edit) {
      vm.passwordMinLength = 0
      $scope.user = ResolvedUser
      $scope.isExternal = $scope.user.source !== 'local'
      delete $scope.user.password
      vm.originalUser = _.cloneDeep($scope.user)

      $scope.user.roles = $scope.user.roles.map(function (role) {
        return role.name
      })
      // $scope.user.isEditor = _.find(_.cloneDeep($scope.user.roles), { name: 'editor' })

      if ($rootScope.appSettings.loginWithCard) {
        const onComplete = async barcode => {
          console.log('onComplete', barcode)
          try {
            await UserModel.prototype$setScanningValue(
              { id: $scope.user.id },
              { scanningValue: barcode }
            ).$promise
            const message = $translate.instant('USER.SAVE_SUCCESS')
            $mdToast.show({
              template:
                '<md-toast id="language-message" layout="column" layout-align="center start"><div class="md-toast-content">' +
                message +
                '</div></md-toast>',
              hideDelay: 3000,
              position: $rootScope.toastLocation,
              parent: '#content'
            })
          } catch (e) {
            console.log('Error saving card value', e)
          }
        }
        const options = {
          onComplete
        }
        const scannerDetector = new window.ScannerDetector(options)
        console.log(scannerDetector)
      }
    } else {
      $scope.user = {
        roles: ['$operator'],
        setPassword: !vm.smtpEnabled,
        mediaAutoLoad: true,
        sendDigestEmail: true,
        digestEmailScheduling: [],
        displayRecordsApprovalPopup: true,
        dateTimeFormats: {
          date: null,
          time: null
        },
        timezone: null
      }
    }
  }

  vm.changeRole = function changeRole () {
    vm.updateRolesFlag = true
  }

  const initScreen = function initScreen () {
    vm.edit = !!$stateParams.id
    vm.currentUser = $rootScope.currentUser
    initStationsOptions()
    $scope.havePermission = PermissionUtils.isPermit(
      'UserModel',
      'updateUserRoles'
    )
    $scope.haveCreatePermission = PermissionUtils.isPermit(
      'UserModel',
      'createNewUser'
    )
    vm.smtpEnabled = $rootScope.appSettings.smtp || false
    vm.updateRolesFlag = false

    vm.load()

    const actionRow = [
      {
        key: 'locationIds',
        type: 'modelSelect',
        className: 'max-width-30 flex-30',
        templateOptions: {
          input_container_class: 'no-icon',
          label: $translate.instant('Location.LOCATIONS'),
          multiple: true,
          findMethod: Location.find,
          mapObject: { id: 'id', name: 'name' },
          baseFilterObject: {
            where: {},
            fields: {
              name: true,
              id: true
            }
          }
        }
      },
      {
        key: 'groups',
        type: 'modelSelect',
        className: 'max-width-30 flex-30',
        templateOptions: {
          input_container_class: 'no-icon',
          label: $translate.instant('USER.GROUPS'),
          multiple: true,
          findMethod: Group.find,
          mapObject: { id: 'id', name: 'name' },
          baseFilterObject: {
            where: {},
            fields: {
              name: true,
              id: true
            }
          }
        }
      },
      {
        key: 'stationId',
        type: 'selectWithSearch',
        className: 'flex-20',
        templateOptions: {
          label: $translate.instant('USER.WORK_STATION'),
          options: vm.stations,
          onChange: function (value, options) {
            options.validation.errorExistsAndShouldBeVisible = null
            options.formControl.$setValidity('serverError', true)
          }
        }
      }
    ]

    if ($scope.havePermission) {
      actionRow.push({
        key: 'roles',
        type: 'selectWithSearch',
        className: 'flex-20',
        templateOptions: {
          label: $translate.instant('USER.PERMISSIONS'),
          multiple: true,
          required: true,
          searchPlaceholder: 'Search...',
          options: vm.serverRoles.map(role => {
            role.id = role.name
            role.name = role.nicename
            role.notAnOption = role.disableUse
            return role
          }),
          enableRemoveDisabledSelected: true,
          onChange: function (value, options) {
            vm.changeRole()
            options.validation.errorExistsAndShouldBeVisible = null
            options.formControl.$setValidity('serverError', true)
          }
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return $scope.isExternal
          }
        }
      })
    }

    const staticUserFields = [
      {
        className: 'display-flex layout-row',
        fieldGroup: [
          {
            key: 'email',
            type: 'input',
            className: 'flex-50 margin-2',
            ngModelAttrs: {
              'new-password': {
                value: 'autocomplete'
              }
            },
            templateOptions: {
              type: 'text',
              focus: true,
              required: true,
              label: $translate.instant('USER.email'),
              placeholder: $translate.instant('USER.email'),
              onChange: function (value, options) {
                options.validation.errorExistsAndShouldBeVisible = null
                options.formControl.$setValidity('serverError', true)
              }
            },
            expressionProperties: {
              'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
                return $scope.isExternal
              }
            }
          },
          {
            key: 'username',
            type: 'input',
            className: 'flex-50 margin-2',
            ngModelAttrs: {
              'new-password': {
                value: 'autocomplete'
              }
            },
            templateOptions: {
              type: 'text',
              label: $translate.instant('USER.username'),
              required: true,
              placeholder: $translate.instant('USER.username'),
              onChange: function (value, options) {
                options.validation.errorExistsAndShouldBeVisible = null
                options.formControl.$setValidity('serverError', true)
              }
            },
            expressionProperties: {
              'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
                return $scope.isExternal
              }
            }
          }
        ]
      },
      {
        className: 'display-flex layout-row',
        fieldGroup: [
          {
            key: 'firstName',
            type: 'input',
            className: 'flex-25',
            templateOptions: {
              type: 'text',
              required: true,
              label: $translate.instant('USER.firstName'),
              placeholder: $translate.instant('USER.firstName'),
              onChange: function (value, options) {
                options.validation.errorExistsAndShouldBeVisible = null
                options.formControl.$setValidity('serverError', true)
              }
            },
            expressionProperties: {
              'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
                return $scope.isExternal
              }
            }
          },
          {
            key: 'lastName',
            type: 'input',
            className: 'flex-25',
            templateOptions: {
              type: 'text',
              required: true,
              label: $translate.instant('USER.lastName'),
              placeholder: $translate.instant('USER.lastName'),
              onChange: function (value, options) {
                options.validation.errorExistsAndShouldBeVisible = null
                options.formControl.$setValidity('serverError', true)
              }
            },
            expressionProperties: {
              'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
                return $scope.isExternal
              }
            }
          },
          {
            key: 'phone',
            type: 'phoneInput',
            className: 'flex-50',
            templateOptions: {
              type: 'number',
              label: $translate.instant('USER.PHONE_NUMBER'),
              onChange: function (value, options) {
                options.validation.errorExistsAndShouldBeVisible = null
                if (options.formControl && options.formControl.$setValidity) {
                  options.formControl.$setValidity('serverError', true)
                }
              }
            },
            expressionProperties: {
              'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
                return $scope.isExternal
              }
            }
          }
        ]
      },
      {
        className: 'display-flex layout-row',
        fieldGroup: [
          {
            key: 'profileResourceId',
            type: 'upload',
            templateOptions: {
              container: 'Profile',
              label: $translate.instant('USER.PROFILE_IMAGE'),
              caption: $translate.instant('USER.PROFILE_IMAGE'),
              mode: 'full',
              keyIs: 'id',
              filetype: 'image',
              displaytype: 'upload',
              keepOriginal: false,
              object: 'profileResource',
              onClick: function (value, options) {
                options.validation.errorExistsAndShouldBeVisible = false
                options.formControl.$setValidity('serverError', true)
              }
            }
          }
        ]
      },
      {
        className: 'display-flex layout-row',
        fieldGroup: [
          {
            key: 'manager',
            type: 'selectUser',
            className: 'flex-30',
            templateOptions: {
              label: $translate.instant('USER.MANAGER'),
              placeholder: $translate.instant('USER.MANAGER'),
              allowEmpty: true,
              showConstants: false,
              showAssignee: false,
              onChange: function (value, options) {}
            },
            expressionProperties: {
              'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
                return $scope.isExternal
              }
            }
          },
          {
            key: 'employeeNumber',
            type: 'input',
            className: 'flex-30',
            ngModelAttrs: {
              'new-password': {
                value: 'autocomplete'
              }
            },
            templateOptions: {
              type: 'text',
              label: $translate.instant('USER.employeeNumber'),
              placeholder: $translate.instant('USER.employeeNumber'),
              onChange: function (value, options) {
                options.validation.errorExistsAndShouldBeVisible = null
                options.formControl.$setValidity('serverError', true)
              }
            }
          },
          /**
           * FF Hack start
           * FF doesn't respect the autocomplete=off or autocompete=new-password.
           * Therefore need to add fake fields regarding username and password.
           */
          {
            key: 'fakeusername',
            type: 'input',
            hideExpression: () => true,
            templateOptions: {
              type: 'text'
            }
          },
          {
            key: 'fakepassword',
            type: 'input',
            hideExpression: () => true,
            templateOptions: {
              type: 'password'
            }
          },
          /**
           * FF Hack end
           */
          {
            key: 'setPassword',
            type: 'checkbox',
            className: 'flex-30',
            templateOptions: {
              label: $translate.instant('USER.SET_PASSWORD'),
              onChange: function (value, options) {
                options.validation.errorExistsAndShouldBeVisible = null
                options.formControl.$setValidity('serverError', true)
              }
            },
            hideExpression: function ($viewValue, $modelValue, scope) {
              if ($scope.isExternal) return true
              return vm.edit || !vm.smtpEnabled
            }
          },
          {
            key: 'password',
            type: 'input',
            className: 'flex-30',
            ngModelAttrs: {
              'new-password': {
                value: 'autocomplete'
              }
            },
            templateOptions: {
              type: 'password',
              label:
                $scope.user.source === 'saml'
                  ? $translate.instant('USER.PIN_CODE')
                  : $translate.instant('USER.PASSWORD'),
              placeholder: vm.edit
                ? $translate.instant('USER.LEAVE_BLANK_FOR_UNCHANGE')
                : $scope.user.source === 'saml'
                ? $translate.instant('USER.PIN_CODE')
                : $translate.instant('USER.PASSWORD'),
              onChange: function (value, options) {
                options.validation.errorExistsAndShouldBeVisible = null
                options.formControl.$setValidity('serverError', true)
              }
            },
            hideExpression: function ($viewValue, $modelValue, scope) {
              return !vm.edit && !scope.model.setPassword && vm.smtpEnabled
            }
          }
        ]
      },
      {
        className: 'display-flex layout-row',
        fieldGroup: actionRow
      },
      {
        className: 'display-flex layout-row',
        fieldGroup: [
          {
            key: 'dateTimeFormats.date',
            type: 'select',
            className: 'max-width-30 flex-30',
            templateOptions: {
              required: true,
              label: $translate.instant('USER.DATE_FORMAT'),
              options: [
                {
                  value: null,
                  name: $translate.instant('USER.SYSTEM_DEFAULT')
                },
                ...DateTimeFormatService.getAvailableDateFormats()
              ]
            }
          },
          {
            key: 'dateTimeFormats.time',
            type: 'select',
            className: 'max-width-30 flex-30',
            templateOptions: {
              required: true,
              label: $translate.instant('USER.TIME_FORMAT'),
              options: [
                {
                  value: null,
                  name: $translate.instant('USER.SYSTEM_DEFAULT')
                },
                ...DateTimeFormatService.getAvailableTimeFormats()
              ]
            }
          },
          {
            key: 'timezone',
            type: 'selectWithSearch',
            className: 'flex time-zone-select',
            templateOptions: {
              required: true,
              options: [
                {
                  id: null,
                  name: $translate.instant('USER.SYSTEM_DEFAULT')
                },
                ...timezones.map(timezone => ({
                  id: timezone,
                  name: timezone
                }))
              ],
              label: $translate.instant('WO.TEMPLATE.TIME_ZONE'),
              onChange: function (value, options) {}
            }
          }
        ]
      },
      {
        className: 'display-flex layout-row',
        fieldGroup: [
          {
            key: 'mediaAutoLoad',
            type: 'checkbox',
            className: 'flex-20',
            templateOptions: {
              label: $translate.instant('USER.mediaAutoLoad'),
              onChange: function (value, options) {
                options.validation.errorExistsAndShouldBeVisible = null
                options.formControl.$setValidity('serverError', true)
              }
            }
          },
          {
            key: 'api',
            type: 'checkbox',
            className: 'flex-20',
            templateOptions: {
              label: $translate.instant('USER.api'),
              onChange: function (value, options) {
                options.validation.errorExistsAndShouldBeVisible = null
                options.formControl.$setValidity('serverError', true)
              }
            }
          }
        ]
      },
      {
        className: 'display-flex layout-row',
        fieldGroup: [
          {
            key: 'sendDigestEmail',
            type: 'checkbox',
            className: 'flex-20',
            templateOptions: {
              label: $translate.instant('USER.SEND_DIGEST_EMAIL'),
              onChange: function (value, options) {
                options.validation.errorExistsAndShouldBeVisible = null
                options.formControl.$setValidity('serverError', true)
              }
            },
            expressionProperties: {
              'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
                return !$rootScope.appSettings.digestEmail.digestEmailEnabled
              }
            }
          }
        ]
      },
      {
        className: 'display-flex layout-column',
        fieldGroup: [
          {
            key: 'digestEmailScheduling',
            type: 'repeatingTemplate',
            className: 'scheduling-repeating-template',
            templateOptions: {
              layout: 'column',
              title: $translate.instant('USER.DIGEST_EMAIL_SCHEDULES'),
              btnText: $translate.instant('WO.TEMPLATE.BUTTON.ADD'),
              hideMovementButtons: true,
              addParameters: () => ({
                id: UUID(),
                frequency: 'Daily',
                day: 1,
                month: 1,
                time: DateTimeFormatService.formatDateTime(
                  new Date().setHours(8, 0, 0),
                  'time'
                ),
                date: new Date()
              }),
              onChange: function () {},
              fields: [
                {
                  key: 'frequency',
                  type: 'segmentedButtons',
                  className: 'flex',
                  templateOptions: {
                    multiple: false,
                    options: frequency.map(freq => ({
                      id: freq,
                      name: $translate.instant(
                        `WO.TEMPLATE.SCHEDULE_OPTIONS.${freq}`
                      )
                    })),
                    label: $translate.instant('WO.TEMPLATE.FREQUENCY')
                  }
                },
                {
                  className: 'display-flex layout-row',
                  fieldGroup: [
                    {
                      className: 'margin-5 padding-top-25',
                      template: '<span translate="WO.TEMPLATE.ON"></span>'
                    },
                    {
                      key: 'day',
                      type: 'input',
                      className: 'width-150 margin-5',
                      templateOptions: {
                        type: 'number',
                        required: true,
                        min: 1,
                        max: 31,
                        label: $translate.instant('WO.TEMPLATE.DAY_IN_MONTH'),
                        onChange: function (value, options) {}
                      }
                    },
                    {
                      key: 'month',
                      type: 'select',
                      className: 'width-250 margin-5',
                      templateOptions: {
                        required: true,
                        options: months.map((month, idx) => ({
                          value: idx,
                          name: $translate.instant(
                            `WO.TEMPLATE.MONTHS.${month}`
                          )
                        })),
                        label: $translate.instant('WO.TEMPLATE.MONTH')
                      },
                      expressionProperties: {
                        'templateOptions.disabled': (
                          $viewValue,
                          $modelValue,
                          scope
                        ) => {
                          return !$rootScope.appSettings.digestEmail
                            .digestEmailEnabled
                        }
                      }
                    }
                  ],
                  hideExpression: function ($viewValue, $modelValue, scope) {
                    return scope.model.frequency !== 'Yearly'
                  }
                },
                {
                  className: 'display-flex layout-row',
                  fieldGroup: [
                    {
                      className: 'margin-5 padding-top-25',
                      template:
                        '<span translate="WO.TEMPLATE.RECUR_ON_DAY"></span>'
                    },
                    {
                      key: 'day',
                      type: 'input',
                      className: 'width-150 margin-5',
                      templateOptions: {
                        type: 'number',
                        required: true,
                        min: 1,
                        max: 31,
                        label: $translate.instant('WO.TEMPLATE.DAY'),
                        onChange: function (value, options) {}
                      }
                    }
                  ],
                  hideExpression: function ($viewValue, $modelValue, scope) {
                    return scope.model.frequency !== 'Monthly'
                  }
                },
                {
                  key: 'days',
                  type: 'segmentedButtons',
                  className: 'flex margin-top-10',
                  templateOptions: {
                    multiple: true,
                    options: days.map((day, idx) => ({
                      id: idx,
                      name: $translate.instant(
                        `WO.TEMPLATE.SCHEDULE_OPTIONS.${day}`
                      )
                    })),
                    label: $translate.instant('WO.TEMPLATE.DAYS')
                  },
                  hideExpression: function ($viewValue, $modelValue, scope) {
                    return scope.model.frequency !== 'Weekly'
                  }
                },
                {
                  className: 'display-flex layout-row',
                  fieldGroup: [
                    {
                      key: 'time',
                      type: 'timePicker',
                      className: 'flex',
                      templateOptions: {
                        required: true,
                        label: $translate.instant('WO.TEMPLATE.TIME'),
                        onChange: function (value, options) {}
                      }
                    }
                  ]
                }
              ]
            },
            expressionProperties: {
              'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
                return !$rootScope.appSettings.digestEmail.digestEmailEnabled
              }
            }
          },
          {
            template: `<div layout="row" layout-align="start start">
                        <h3 translate="USER.SETTINGS_DIGEST_EMAIL_SCHEDULES" style="margin-top: 0px;"></h3>
                      </div>`,
            hideExpression: function ($viewValue, $modelValue, scope) {
              return scope.model.digestEmailScheduling.length > 0
            }
          }
        ],
        hideExpression: function ($viewValue, $modelValue, scope) {
          return !scope.model.sendDigestEmail
        }
      },
      {
        className: 'display-flex layout-row',
        fieldGroup: [
          {
            key: 'displayRecordsApprovalPopup',
            type: 'checkbox',
            className: 'flex-20',
            templateOptions: {
              label: $translate.instant(
                'USER.DISPLAY_TIME_LOG_RECORDS_APPROVAL_POP_UP'
              ),
              onChange: function (value, options) {
                options.validation.errorExistsAndShouldBeVisible = null
                options.formControl.$setValidity('serverError', true)
              }
            },
            expressionProperties: {
              'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
                return !$rootScope.appSettings.displayRecordsApprovalPopup
              }
            }
          }
        ]
      }
    ]

    $scope.userFields = FormlyHelper.buildFields(staticUserFields, UserModel)
  }

  initScreen()
  const enableLocalActivateDeactivate =
    vm.edit &&
    ($scope.user.source === 'local' ||
      ($scope.user.source === 'saml' &&
        $rootScope.appSettings.provisioning?.enabled === false))
  $scope.headerOptions = {
    icon: 'icon-account',
    template: require('app/templates/headers/simple.html'),
    title: vm.edit
      ? `${$translate.instant('USER.USER')}: ${vm.originalUser.firstName} ${
          vm.originalUser.lastName
        }`
      : $translate.instant('USER.CREATE_USER'),
    fabButton: {},
    menuHideExpression: menu => {
      return (
        menu.every(menuItem => menuItem.hideExpression() === true) ||
        vm.currentUser.id === $scope.user.id
      )
    },
    menu: [
      {
        content: `<md-button ng-click="deActivateUser()">
                    <md-icon md-font-icon="icon-account-minus"></md-icon>
                    <span>${$translate.instant('USER.DEACTIVATE')}</span>
                  </md-button>`,
        hideExpression: () => {
          return !enableLocalActivateDeactivate || !$scope.user.active
        }
      },
      {
        content: `<md-button ng-click="activateUser()">
                    <md-icon md-font-icon="icon-account-plus"></md-icon>
                    <span>${$translate.instant('USER.ACTIVATE')}</span>
                  </md-button>`,
        hideExpression: () =>
          !enableLocalActivateDeactivate || $scope.user.active
      },
      {
        content: `<md-button ng-click="resetUserPassword()">
                    <md-icon md-font-icon="icon-lock-outline"></md-icon>
                    <span>${$translate.instant(
                      $scope.user.source === 'saml'
                        ? 'USER.REQUEST_PIN_CHANGE'
                        : 'USER.REQUEST_PASSWORD_CHANGE'
                    )}</span>
                  </md-button>`,
        hideExpression: () => !vm.edit
      }
    ]
  }
}

module.exports = UserModelShowController
