require('./tinymce-display.component.scss')

const { each } = require('async')
/** @ngInject */
const tinymceDisplayComponent = {
  bindings: {
    ngModel: '<',
    ngDisabled: '<'
  },
  template: '',
  controller: function (
    $sce,
    $element,
    UsersService,
    $window,
    $compile,
    $scope
  ) {
    const $ctrl = this

    const handleMentions = async function handleMentions (content) {
      const mentions = content.match(/@USER_[a-z0-9-]+@/gi)
      if (mentions) {
        await each(mentions, async (mention, cb) => {
          const userId = mention.replace(/@USER_|@/gi, '')
          const displayName = await UsersService.getUserDisplayNameById(userId)
          content = content.replace(
            mention,
            `<a class="mention">@${displayName}</a>`
          )
          cb(null)
        })
      }
      return content
    }

    const addClassNameToImgTag = function addClassNameToImgTag (content) {
      const imgRegex = /(<img[^>]+)(src="([^">]+)")/g
      content = content.replace(
        imgRegex,
        '$1 md-lightbox="true" lazy-load="$3" $2'
      )
      return content
    }

    const manipulateHtml = async function manipulateHtml (unsafeHTML) {
      if (!unsafeHTML) return ''
      $window.DOMPurify.addHook('afterSanitizeAttributes', function (node) {
        if (node.tagName.toLowerCase() !== 'a' && node.hasAttribute('target')) {
          node.removeAttribute('target')
        }
      })
      let safeHTML = $window.DOMPurify.sanitize(unsafeHTML, {
        ADD_ATTR: [
          'class',
          'ta-insert-video',
          'lazy-load',
          'md-lightbox',
          'target',
          'style'
        ]
      })
      safeHTML = await handleMentions(safeHTML)
      safeHTML = addClassNameToImgTag(safeHTML)
      return safeHTML
    }

    this.$onInit = async function () {}

    this.$postLink = function () {}

    this.$onChanges = async function (changes) {
      if (changes.ngModel) {
        $ctrl.viewModel = await manipulateHtml(
          $sce.valueOf(changes.ngModel.currentValue)
        )
        $element.html($ctrl.viewModel)
        $compile($element.contents())($scope)
      }
    }

    this.$onDestroy = function () {}
  },
  require: {
    ngModelCtrl: 'ngModel'
  }
}
module.exports = tinymceDisplayComponent
