/* global _ */

/** @ngInject */
function AsBuiltController (
  $scope,
  $rootScope,
  $translate,
  PermissionUtils,
  KendoGridHelper,
  ViewsService,
  Page,
  Stock,
  htmlWork,
  DateTimeFormatService
) {
  const initScreen = async function initScreen () {
    $scope.title = $rootScope.title = $translate.instant('ASBUILT.TITLE')
    Page.setTitleText($scope.title)
    $scope.PermissionUtils = PermissionUtils

    const tableColumns = [
      // END
      {
        uniqueId: '03b5ebc4-445e-427a-890f-7bffa0f43c18',
        field: 'end',
        translateCode: 'ASBUILT.end',
        template: data => {
          return data.end
            ? DateTimeFormatService.formatDateTime(data.end, 'dateTime')
            : '--'
        }
        // media: '(min-width: 768px)',
      },
      // SKU
      {
        uniqueId: '52042a98-e923-4232-8d77-3bba22189fe1',
        field: 'sku',
        translateCode: 'ASBUILT.sku'
        // media: '(min-width: 768px)',
      },
      // SKU_DESC
      {
        uniqueId: '5a6f0977-05dd-4f31-b2f7-fa8a10ca1d8b',
        field: 'skuDesc',
        translateCode: 'ASBUILT.skuDesc'
        // media: '(min-width: 768px)',
      },
      // SERIAL
      {
        uniqueId: 'b4517084-13f4-4962-98f2-3be3a352ddb7',
        field: 'serial',
        translateCode: 'ASBUILT.serial'
        // media: '(min-width: 768px)',
      },
      // ACTIONS
      {
        uniqueId: 'b83bd1e4-e9a0-4ab4-be0c-1192a8e372e7',
        field: 'workflowSessionItemId',
        translateCode: 'As Built',
        // media: '(min-width: 768px)',
        filterable: false,
        sortable: false,
        trustedTemplate: data => {
          return `<div layout="row" layout-align="start center">
                    <md-button  class="edit-button md-icon-button"
                                ui-sref="app.workflow.workflow-session-item-report({workflowSessionId : '${htmlWork.escapeHTMLQuotes(
                                  data.workflowSessionItemId
                                )}'})" >
                      <md-icon md-font-icon="icon-file-document-box" class="s20"></md-icon>
                    </md-button>
                  </div>`
        }
      }
    ]

    const basicFields = {
      id: true,
      sku: true,
      skuDesc: true,
      workflowSessionItemId: true,
      serial: true,
      end: true
    }

    const { defaultTableColumns, customFieldsDisplay } =
      ViewsService.generateCustomFieldColumns('Stock', tableColumns)

    const baseFilter = {
      where: {
        end: { neq: null }
      },
      order: 'modified DESC',
      fields: _.merge(basicFields, customFieldsDisplay)
    }

    const { columns, selectedViewId } =
      ViewsService.getTablesColumns(defaultTableColumns)

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter)
    )

    const defaultTableSetup = {
      // stateName
      // ignoreParams
      find: Stock.find,
      count: Stock.count,
      cleanBaseFilter: baseFilter,
      baseFilter: newBaseFilter,
      selectedViewId,
      columns: defaultTableColumns
    }

    const defaultTableToolbarSetup = {
      // stateName
      columns: defaultTableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters,
      selectedViewId,
      title: $scope.title
    }

    $scope.kendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.kendoGrid.isBound()

    $scope.tableToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.kendoGrid,
      $scope
    )

    $scope.$applyAsync()
  }

  initScreen()

  $scope.headerOptions = {
    icon: 'icon-file-document-box',
    template: require('app/templates/headers/simple.html'),
    title: $scope.title,
    fabButton: {}
  }
}

module.exports = AsBuiltController
