/* global _ */
require('./my-home.scss')
/** @ngInject */
function MyHomeController (
  $scope,
  $rootScope,
  $translate,
  $state,
  $mdDialog,
  Page,
  ResolvedData,
  ResolvedLocations,
  ResolvedUsers,
  ResolvedTypes,
  NotificationUtils,
  KendoGridHelper,
  ViewsService,
  WorkorderUtils,
  Workflow,
  Workorder,
  FormData,
  htmlWork,
  ResolvedWorkorderStatus,
  PermissionUtils,
  FormUtils,
  DialogService,
  Form,
  $mdToast
) {
  const hasCreateSessionPermissions = PermissionUtils.isPermit(
    'WorkflowSessionItem',
    'enterSession'
  )

  $scope.showInviteButton =
    PermissionUtils.isPermit('UserModel', 'createNewUser') &&
    !$rootScope.appSettings?.disableLocalUserCreation &&
    !$rootScope.appSettings?.['authentication-saml']?.enabled &&
    !$rootScope.appSettings?.['authentication-ldap']?.enabled

  $scope.loadMoreNotifications = function loadMoreNotifications () {
    NotificationUtils.loadMore($rootScope.notifications.length)
  }

  $scope.deleteWorkorder = async function deleteWorkorder (
    workorderId,
    workorderNumber
  ) {
    await WorkorderUtils.deleteWorkorder(workorderId, workorderNumber)
    $scope.workordersKendoGrid.reloadData()
  }

  const users = ResolvedUsers

  const initWorkOrderTable = async function initWorkOrderTable () {
    $scope.usersPerRow = {}
    $scope.assigneePerRow = {}
    const workorderStateName = `${$state.current.name}.workorders`

    const baseFilter = {
      order: ['priority DESC', 'expectedDate DESC', 'modified DESC'],
      where: { sendToNextPlus: true },
      fields: {
        kitItems: false,
        KITITEMS_SUBFORM: false,
        TRANSORDER_K_SUBFORM: false,
        orderLines: false
      },
      include: [
        {
          relation: 'stocks',
          scope: {
            fields: { kitStatus: true, end: true, quantity: true }
          }
        },
        {
          relation: 'sessions',
          scope: {
            fields: { id: true, end: true, quantity: true }
          }
        },
        {
          relation: 'groupModel',
          scope: {
            fields: { id: true, name: true }
          }
        },
        {
          relation: 'workorderType',
          scope: {
            fields: { id: true, name: true }
          }
        }
      ]
    }

    if (
      _.isArray($rootScope.currentUser.locationIds) &&
      $rootScope.currentUser.locationIds.length > 0
    ) {
      baseFilter.where.locationIds = { inq: $rootScope.currentUser.locationIds }
    }
    if ($rootScope.appSettings.customHomePage.workorder === 'opened') {
      baseFilter.where.end = null
      baseFilter.where.and = [{ erpClosed: false }, { closed: false }]
    } else if ($rootScope.appSettings.customHomePage.workorder === 'mine') {
      baseFilter.where.assignedUsers = { inq: $rootScope.currentUser.id }
      baseFilter.where.end = null
      baseFilter.where.and = [{ erpClosed: false }, { closed: false }]
    } else {
      console.error(
        `Unknown workorder filter: ${$rootScope.appSettings.customHomePage.workorder}`
      )
    }

    const tableColumns = WorkorderUtils.generateWorkorderColumns(
      $scope,
      ResolvedLocations,
      users,
      ResolvedWorkorderStatus
    )
    const { defaultTableColumns } = ViewsService.generateCustomFieldColumns(
      'Workorder',
      tableColumns
    )

    const { columns, selectedViewId } = ViewsService.getTablesColumns(
      defaultTableColumns,
      workorderStateName
    )

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter),
      workorderStateName
    )

    const defaultTableSetup = {
      stateName: workorderStateName,
      ignoreParams: true,
      find: Workorder.find,
      count: Workorder.count,
      pageSize: 5,
      cleanBaseFilter: baseFilter || {},
      baseFilter: newBaseFilter,
      selectedViewId,
      columns: defaultTableColumns
    }

    const defaultTableToolbarSetup = {
      stateName: workorderStateName,
      columns: defaultTableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters,
      selectedViewId,
      title: $scope.title
    }

    $scope.workordersKendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.workordersKendoGrid.isBound()

    $scope.workordersToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.workordersKendoGrid,
      $scope
    )

    $scope.workorderTableInit = false

    $scope.$applyAsync()
  }

  const initMyFormsTable = async function initMyFormsTable () {
    const myFormsStateName = `${$state.current.name}.my-forms`

    const baseFilter = {
      order: ['modified DESC']
    }
    const tableColumns = FormUtils.generateFormColumns(
      'multiForm',
      null,
      null,
      users,
      [],
      $scope,
      'myFormsKendoGrid'
    )

    const { defaultTableColumns } = ViewsService.generateCustomFieldColumns(
      'FormData',
      tableColumns
    )

    const { columns, selectedViewId } = ViewsService.getTablesColumns(
      defaultTableColumns,
      myFormsStateName
    )

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter),
      myFormsStateName
    )

    const defaultTableSetup = {
      stateName: myFormsStateName,
      ignoreParams: true,
      find: FormData.getMyFormDataList,
      count: FormData.getMyFormDataCount,
      pageSize: 5,
      cleanBaseFilter: baseFilter || {},
      baseFilter: newBaseFilter,
      selectedViewId,
      columns: defaultTableColumns,
      encodeTitles: true
    }

    const defaultTableToolbarSetup = {
      stateName: myFormsStateName,
      columns: defaultTableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters,
      selectedViewId,
      title: $scope.title
    }

    $scope.myFormsKendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.myFormsKendoGrid.isBound()

    $scope.myFormsToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.myFormsKendoGrid,
      $scope
    )

    $scope.myFormTableInit = false

    $scope.$applyAsync()
  }

  const initWaitingFormsTable = async function initWaitingFormsTable () {
    const waitingFormsStateName = `${$state.current.name}.waiting-forms`

    const baseFilter = {
      order: ['modified DESC']
    }

    const tableColumns = FormUtils.generateFormColumns(
      'multiForm',
      null,
      null,
      users,
      [],
      $scope,
      'waitingFormsKendoGrid'
    )

    const { defaultTableColumns } = ViewsService.generateCustomFieldColumns(
      'FormData',
      tableColumns
    )

    const { columns, selectedViewId } = ViewsService.getTablesColumns(
      defaultTableColumns,
      waitingFormsStateName
    )

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter),
      waitingFormsStateName
    )

    const defaultTableSetup = {
      stateName: waitingFormsStateName,
      ignoreParams: true,
      find: FormData.getWaitingFormDataList,
      count: FormData.getWaitingFormDataCount,
      pageSize: 5,
      cleanBaseFilter: baseFilter || {},
      baseFilter: newBaseFilter,
      selectedViewId,
      columns: defaultTableColumns,
      encodeTitles: true
    }

    const defaultTableToolbarSetup = {
      stateName: waitingFormsStateName,
      columns: defaultTableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters,
      selectedViewId,
      title: $scope.title
    }

    $scope.waitingFormsKendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.waitingFormsKendoGrid.isBound()

    $scope.waitingFormsToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.waitingFormsKendoGrid,
      $scope
    )

    $scope.waitingFormTableInit = false

    $scope.$applyAsync()
  }

  $scope.deleteFormData = async function deleteFormData (formDataId, formId) {
    const form = await Form.findOne({ filter: { where: { id: formId } } })
      .$promise
    const canView = FormUtils.isPermit('view', form)
    if (canView) {
      DialogService.deleteDialog(
        $translate.instant(
          'FORM.DELETE_MESSAGE',
          {},
          null,
          null,
          'sceParameters'
        )
      ).then(
        function () {
          FormData.destroyById({ id: formDataId })
            .$promise.then(() => {
              $scope.myFormsKendoGrid.reloadData()
              $scope.waitingFormsKendoGrid.reloadData()
            })
            .catch(e => console.error(e))
        },
        function () {}
      )
    } else {
      $mdToast.show(
        $mdToast.nextplus({
          position: $rootScope.toastLocation,
          parent: 'body',
          theme: 'error-toast',
          hideDelay: 3000
        })
      )
      $mdToast.updateTextContent(
        $translate.instant('FORM.ERROR.NO_DELETE_PERMISSIONS')
      )
    }
  }

  const initWorkflowsTable = async function initWorkflowsTable () {
    const workflowsStateName = `${$state.current.name}.workflows`
    const tableColumns = [
      // SKU
      {
        uniqueId: '6d03016e-0856-46cf-bc25-cc37211a4065',
        field: 'parts.sku',
        translateCode: 'WF.partSku',
        type: 'string',
        // media: '(min-width: 768px)',
        trustedTemplate: data => {
          const parts = data.parts
          if (!parts) return ''
          const htmlParts = []
          parts.forEach(part => {
            htmlParts.push(
              `<span>${htmlWork.htmlEncode(part.sku) || ''}</span>`
            )
          })
          return htmlParts.join(', ')
        }
      },
      // NAME
      {
        uniqueId: '8b746932-d685-41f7-bc5d-0714d1531327',
        field: 'name',
        translateCode: 'WF.NAME',
        type: 'string'
        // media: '(min-width: 768px)',
      },
      // ACTIONS
      {
        uniqueId: '06bd9c06-9ea6-4138-8f57-9f47ab1ab768',
        field: 'id',
        translateCode: 'COMMON.ACTIONS',
        filterable: false,
        sortable: false,
        headerAttributes: {
          class: 'actions-column-header'
        },
        // media: '(min-width: 768px)',
        trustedTemplate: data => {
          let html = `<div layout="row" layout-align="start center" style="direction: ${$rootScope.dir}">`
          if (hasCreateSessionPermissions) {
            html += `<md-button ng-class="($root.isTabletOrMobile) ? ['md-fab','md-mini','md-primary'] : ['md-icon-button','show-button']" data-testid="workflow.play.${htmlWork.escapeHTMLQuotes(
              data.recordId
            )}" ui-sref="app.workflow.session.show({preview:false, recordId: '${htmlWork.escapeHTMLQuotes(
              data.recordId
            )}'})">
            <md-tooltip>${$translate.instant('WF.CREATE_SESSION')}</md-tooltip>
            <md-icon md-font-icon="icon-play" class="s16 green-500-fg"></md-icon>
            </md-button>`
          }

          html += '</div>'
          return html
        }
      }
    ]

    const basicFields = {
      id: true,
      recordId: true,
      parts: true,
      name: true,
      modified: true,
      addToHomePage: true,
      translations: true,
      fallbackStatus: true
    }

    const { defaultTableColumns, customFieldsDisplay } =
      ViewsService.generateCustomFieldColumns('Workflow', tableColumns)

    const baseFilter = {
      where: { addToHomePage: true, currentReleasedVersion: true },
      order: 'modified DESC',
      fields: _.merge(basicFields, customFieldsDisplay)
    }

    const { columns, selectedViewId } = ViewsService.getTablesColumns(
      defaultTableColumns,
      workflowsStateName
    )

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter),
      workflowsStateName
    )

    const defaultTableSetup = {
      stateName: workflowsStateName,
      ignoreParams: true,
      model: Workflow, // To generate translate keys columns
      find: Workflow.find,
      count: Workflow.count,
      pageSize: 5,
      cleanBaseFilter: baseFilter,
      baseFilter: newBaseFilter,
      selectedViewId,
      columns: defaultTableColumns
    }

    const defaultTableToolbarSetup = {
      stateName: workflowsStateName,
      columns: defaultTableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters,
      selectedViewId,
      title: $scope.title
    }

    $scope.workflowsKendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.workflowsKendoGrid.isBound()

    $scope.workflowsToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.workflowsKendoGrid,
      $scope
    )

    $scope.workflowsTableInit = false

    $scope.$applyAsync()
  }

  const initScreen = async function initScreen () {
    $scope.title = $rootScope.title = $translate.instant('HOME.MY_HOME')
    Page.setTitleText($scope.title)

    $scope.userData = ResolvedData
    $scope.inProgressWorkorders = {
      title: $translate.instant('DASHBOARD.WORKORDERS_IN_QA')
    }

    $scope.newWorkorders = {
      title: $translate.instant('DASHBOARD.REWORK_WORKORDERS')
    }

    $scope.workorderTableInit = true
    $scope.myFormTableInit = true
    $scope.waitingFormTableInit = true
    $scope.workflowsTableInit = true

    initWorkflowsTable()
    initWaitingFormsTable()
    initWorkOrderTable()
    initMyFormsTable()
    // await initWaitingFormsTable()
    // await initWorkflowsTable()

    $scope.$applyAsync()
  }

  initScreen()
}

module.exports = MyHomeController
